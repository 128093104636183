import moment from "moment"
import {getDistance} from "geolib"

export function parseBool(_isBool) {
    let isBool = typeof _isBool === 'string' ? _isBool.toLowerCase() : _isBool

    switch (isBool) {
        case true:
        case 'yes':
            return true
        case 1:
            return true
        case '1':
            return true
        case false:
        case 'no':
            return false
        case 0:
            return false
        case '0':
            return false
        case null:
            return false
        case 'false':
            return false
        case 'true':
            return true
        default:
            return Boolean(_isBool)
    }
}

/**
 * Note: Format date
 * _type : end  start minutesLeft before after
 * @param _date
 * @param _type
 */
export function getFormattedDate(_date, _type) {
    let now = moment()
    let minutesLeft = moment(_date).diff(now, 'minutes')
    let hoursLeft = moment(_date).diff(now, 'hours')

    switch (_type) {
        // Return true if _date is BEFORE now
        case 'after':
            return moment(_date).isBefore(now)
        case 'minuteLeft':
            return minutesLeft
        case 'end':
            if (minutesLeft < 60) {
                return minutesLeft
            } else {
                console.log(minutesLeft / 60)
            }
            break

        case 'start':
            return moment(_date).calendar()

    }
}

/**
 *
 * @param _userLat
 * @param _userLng
 * @param _objLat
 * @param _objLng
 * @returns {number}
 */
export function readableDistance(_userLat, _userLng, _objLat, _objLng) {
    let distance = undefined
    if (_userLat != null && _userLng != null && _objLat != null && _objLng != null) {
        let a, b, c, d
        a = _userLat.toString()
        b = _userLng.toString()
        c = _objLat.toString()
        d = _objLng.toString()
        distance = getDistance({
            latitude: a,
            longitude: b,
        }, {
            latitude: c,
            longitude: d
        })
        if (distance > 999)
            distance = (distance / 1000).toFixed(1) + " km"
        else
            distance += " m"
    }

    return distance
}


export function readableTarif(_isFree = false, _tarif = 0, _promo = 0) {
        console.log(parseBool(_isFree))

    if (parseBool(_isFree) === true )
        return "Gratuit"
    else if ( parseFloat(_promo) && parseFloat(_promo)>0 )
        return _promo + "€ au lieu de " + _tarif + "€"
    else if (parseFloat(_tarif) > 0)
        return _tarif + "€"
    else return ""

}
