import React, {useRef, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'


// Components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import Button from 'react-bootstrap/Button'
import moment from "moment"
import 'moment/locale/fr'
import {getDistance} from "geolib"

// Private Component
import config from "../../config"
import {locales} from "../../utils/locale/MomentLocale"

// Styles
import "./eventScroller.styles.css"

// API
import {showMixedEvents} from "../../api/eventActions"

const MAX_POSSIBLE_HEIGHT = 390
const BASE_URL = config.BASE_URL

// SCROLL_MARGIN_BANG  valeur en pourcentage pour le calcul de la position de lancement de la requete de chargement de nouveaux éléments
const SCROLL_MARGIN_BANG = 20
let scrollEnd = false
let page = 1

// moment.updateLocale('fr', {
//     calendar: {
//         lastDay: '[Hier à] LT',
//         sameDay: "[Aujourd'hui jusqu'à] LT",
//         nextDay: '[demain  jusqu\'à] LT',
//         lastWeek: '[La semaine dernière] dddd [ jusqu\'à] LT',
//         nextWeek: '[ce] dddd [ jusqu\'à] LT',
//         sameElse: 'dddd DD MMM  jusqu\'à HH:mm'
//     }
//
// })


const ExpendableText = ({maxHeight, children, IsXml, Eid, coords}) => {
    const ref = useRef()
    const [shouldShowExpand, setShouldShowExpand] = useState(false)
    const [expanded, setExpanded] = useState(true)

    useEffect(() => {
        if (ref.current.scrollHeight > maxHeight) {
            setShouldShowExpand(true)
            setExpanded(false)
        }
    }, [maxHeight])


    return (
        <Card.Body ref={ref} className="sp-card-body">
            <div
                className={` ${expanded ? "inner expanded" : "inner"}`}
                style={{maxHeight: expanded ? MAX_POSSIBLE_HEIGHT : maxHeight}}
            >
                {children}
            </div>
            {shouldShowExpand && (
                <Button variant="outline-info" className='m-1' onClick={() => setExpanded(!expanded)}
                        size='sm'>(...)</Button>
            )}
            <Button variant="outline-info"
                    size='sm'
                    as={Link}
                    target="_blank"
                    to={`/details/${Eid}/${IsXml}`}>Détails
            </Button>
        </Card.Body>
    )
}


function EventScroller(props) {
    const [events, setEvents] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [coords, setCoords] = useState({})
    const [filterList, setFilterList] = useState(null)
    const [filterActive, setFilterActive] = useState(false)
    locales("fr")

    useEffect(()=>{
        if (props.coords)
            setCoords({
                lat: props.coords.lat,
                lng: props.coords.lng
            })
    }, [props.coords])

    /**
     * Note : Managing FILTER
     */
    useEffect(() => {
        if (props.filterList) {
            setFilterList(props.filterList)
            const count = props.filterList.reduce((nb, item) => item.raised === true ? ++nb : nb, 0)
            if (count && count > 0) {
                setFilterActive(true)
            }
            else
                setFilterActive(false)
        }

    }, [props.filterList])

    useEffect(()=>{
        if (props.event) {
            let st = props.event
            // let eventSorting = st.slice(0)
            // eventSorting.sort(function(a,b) {
            //     return a.near - b.near
            // })
            setEvents(st)
        }
    }, [props.event])

    const hasReachedBottom = (pos) => {
        return (document.body.scrollHeight - document.body.offsetHeight) > (pos + ((pos * SCROLL_MARGIN_BANG) / 100)) ? false : true
    }

    const handleScroll = (event) => {
        const {scrollTop, clientHeight, scrollHeight} = event.target
        let lastScrollY = window.scrollY

        if (hasReachedBottom(lastScrollY) === true && scrollEnd === false) {
            //setScrollEnd(true)
            scrollEnd = true
           // loadEvents(page)
        }
    }

    const loadEvents = async (index) => {
        setLoading(true)
        showMixedEvents(index)
            .then(response => {
                if (response.data) {
                    setEvents((prev) => [...prev, ...response.data])
                    setLoading(false)
                    scrollEnd = false
                }
            })
        page++
    }


    useEffect(() => {
        scrollEnd = true
        window.addEventListener("scroll", handleScroll, false)
        loadEvents(page)
    }, [props.initialEventsList])

    return (
        // <div className='EventAdminScroller' onScroll={handleScroll}>
        <Container className="container-flux">
            <Row className="row-flux">
                {
                   events && events.map(function (event, index) {
                        if (event.ev_id) {
                            let bt = undefined
                            if (filterList && filterActive)
                                bt =  filterList.find((item) => item.key === parseInt(event.btid))

                            // Description HTML preparation & cleaning
                            const regex = /(<([^>]+)>)/ig
                            let htmlClx = event.details.replace(regex, '')//.substr(0, 100)
                            if (htmlClx && htmlClx.length > 0) {
                                htmlClx = htmlClx?.replace(/&nbsp;/g, '')
                                htmlClx = htmlClx.length > 160 ? htmlClx.substr(0, 260).trim() + "..." : htmlClx
                            }
                            //Custom distance information
                            let distance = getDistance({
                                latitude: coords.lat,
                                longitude: coords.lng,
                            }, {
                                latitude: event.lat,
                                longitude: event.lng
                            })
                            if (distance > 999)
                                distance = (distance / 1000).toFixed(2) + " km"
                            else
                                distance += " m"

                            // Image management
                            let picUrl = ""
                            if (event.is_xml === "1")
                                picUrl = event.imgPathLarge
                            else {
                                if (event.image_name)
                                    picUrl = BASE_URL + "img/events/" + event.image_name + "?" + event.updated_at
                                else
                                    picUrl = BASE_URL + "img/events/cover-" + event.ev_id + ".jpg?" + event.updated_at
                            }

                            if ( (filterActive && bt.raised === true) || (!filterActive)  ) {
                                return (
                                <Col xs="12" sm="3" key={index} className="col-flux">
                                    <CardGroup className="m-2 d-block sp-align full-w">
                                        <Card className="card-landing card-align-verticaly">
                                            <div className="tester">
                                                <Card.Img src={picUrl}
                                                          className="img img-responsive sp-card-img"/>
                                                <Card.ImgOverlay>
                                                    <Card.Title className="overlay-title">{event.ev_name}</Card.Title>
                                                </Card.ImgOverlay>
                                            </div>
                                            <Card.Footer className="footerX">
                                                <ExpendableText maxHeight={50} Eid={event.ev_id} IsXml={event.is_xml}>
                                                    <p>{moment(event.dt_from).isSame(moment(), "day")
                                                        ? moment(event.dt_from).calendar()
                                                        : moment(event.dt_to).calendar()}{" "}
                                                        <br/> à {distance}</p>
                                                    <p>{htmlClx}</p>
                                                </ExpendableText>
                                                {/*<Card.Link href="#">En savoir plus</Card.Link>*/}
                                                {/*<Card.Link href="#">Star</Card.Link>*/}

                                            </Card.Footer>
                                            {moment(event.dt_to).isSame(moment(), 'day') &&
                                            <div className="ribbon ribbon-top-right">
                                                <span>JustNow !</span>
                                            </div>
                                            }
                                        </Card>
                                    </CardGroup>
                                </Col>
                            )} else return null
                        }
                    })
                }
            </Row>
        </Container>

    )
}

export default EventScroller
