import React from 'react'
import {Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'row',
        marginTop: 24,
    },
    reportTitle:{
        color: '#7c7f84',
        letterSpacing: 1,
        fontSize: 22,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 'bolder',
    }
});


const InvoiceTitle = ({title}) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
)

export default InvoiceTitle
