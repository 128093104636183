import React, {useEffect, useState} from 'react'
import {Text, View, StyleSheet } from '@react-pdf/renderer'
import moment from "moment";

const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'row',
        marginTop: 12
    },
    reportStore:{
        fontSize: 9,
        textAlign: 'center',
        textTransform: 'capitalize',
    },
    reportTitle:{
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    reportPeriod: {
        fontSize: 9,
        textAlign: 'center',
    }
})


const InvoiceThankYouMsg = ({items}) => {
    const [store, setStore] = useState('')

    useEffect(()=> {
        if (items[0].store === 'APP_STORE')
            setStore('Apple Store')
        if (items[0].store === 'PLAY_STORE')
            setStore('Google Play')
    }, [])

    return(
    <View style={styles.titleContainer}>
        <Text>
            <Text style={styles.reportPeriod}>Période du {moment(items[0].dtStart).format('DD/MM/YYYY')} au {moment(items[0].dtEnd).format('DD/MM/YYYY')}{"\n"}</Text>
            <Text style={styles.reportStore}>Règlement effectué via {store}{"\n"}</Text>
            <Text style={styles.reportStore}>Autofacturation{"\n\n"}</Text>
            <Text style={styles.reportTitle}>Merci pour votre confiance</Text>
        </Text>

    </View>
    )
}





export default InvoiceThankYouMsg
