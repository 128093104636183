import React, {useEffect, useState} from "react"


//API
import {getEventDetail, getEventPartnerDetail, reportAnEvent, setVisit} from "../../api/eventActions"

// Component
import {useParams} from 'react-router-dom'
import moment from "moment"
import Image from 'material-ui-image'
import 'moment/locale/fr'
import {
    //EmailShareButton,
    FacebookShareButton,
    //LinkedinShareButton,
    TumblrShareButton,
    //TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    TumblrIcon,
    //TwitterIcon
} from "react-share"
import {confirmAlert} from 'react-confirm-alert'
import parse from 'html-react-parser'

// Private Component
import config from "../../config"
import MiniMap from "../miniMap/MiniMap"
import {history} from '../../_helpers'
import {getFormattedDate} from "../../utils/functions"
import {locales} from '../../utils/locale/MomentLocale'

//Component (Material UI)
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import {makeStyles} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from "@material-ui/core/CardActionArea"
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {red} from '@material-ui/core/colors'
import ReportIcon from '@material-ui/icons/Report'
//import ArrowDropDownCircleTwoToneIcon from '@material-ui/icons/ArrowDropDownCircleTwoTone'
import CloseIcon from '@material-ui/icons/Close'
import PhoneIcon from '@material-ui/icons/Phone'
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"

// Style
import "./detail.css"
import 'react-confirm-alert/src/react-confirm-alert.css'

const BASE_URL = config.BASE_URL
const textLimit = 800
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    map: {
        flexGrow: 1,
    },
    sharingRoot: {
        flexGrow: 1,
    },
    rootCard: {
        maxWidth: '100vh',
    },
    media: {
        height: '100%',
        paddingTop: '80%',//'56.25%', // 16:9
        backgroundSize: 'contain'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
        fontSize: "0.60rem",
        width: 55,
        height: 50
    },
    priceZone: {
        backgroundColor: red[500],
        width: "100%",
        color: "white",
        textAlign: "center",
    },
}));

export default function Detail(props) {
    // Récupération des parametres de l'url
    const {eid, xml} = useParams()
    locales('fr')

    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [imageName, setImageName] = useState("")
    const [tarif, setTarif] = useState("")
    const [tarifPromo, setTarifPromo] = useState("")
    const [dtFrom, setDtFrom] = useState("")
    const [dtTo, setDtTo] = useState("")
    const [isXml, setIsXml] = useState("")
    const [partnerImage, setPartnerImage] = useState("")
    const [isFree, setIsFree] = useState(false)
    const [position, setPosition] = useState(undefined)
    const [disableReport, setDisableReport] = useState(false)
    const [deeplink, setDeeplink] = useState("")
    const [cname, setCname] = useState("")
    const [cphone, setCphone] = useState("")
    const [cdetail, setCdetail] = useState("")
    const [caddress, setCaddress] = useState("")
    const [ccity, setCcity] = useState("")
    const [cid, setCid] = useState(undefined)
    const [updatedAt, setUpdatedAt] = useState("")


    /**
     * Note : Move Up
     */
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    })


    /**
     * Note : STATISTIQUES
     */
    useEffect(() => {
        if (eid)
            setVisit(eid)
                .catch((e) => {
                    console.log("error on setting visit", e)
                })
    }, [eid])


    useEffect(() => {
        if (xml == 0 || xml === undefined) {
            getEventDetail(eid)
                .then(response => {
                    let data = response.data[0]
                    setTitle(data.ev_name)
                    setDtFrom(data.dt_from)
                    setDtTo(data.dt_to)
                    setIsXml(data.is_xml)
                    setImageName(data.image_name)
                    setDescription(data.details)
                    setIsFree(data.is_free)
                    setPosition({
                        lat: data.lat,
                        lng: data.lng,
                    })
                    setTarif(data.tarif)
                    setTarifPromo(data.tarif_promo)
                    setCname(data.cname)
                    setCphone(data.phone)
                    setCdetail(data.detail)
                    setCaddress(data.address)
                    setUpdatedAt(data?.updated_at)
                    setCname(data.c_name)
                    setCid(data.cid)
                    // Page customisation
                    document.title = data.ev_name

                    console.log("Detail", data)
                })
        } else if (xml == 1) {
            getEventPartnerDetail(eid)
                .then(response => {
                    let data = response.data[0]
                    setTitle(data.ev_name)
                    setDtFrom(data.dt_from)
                    setDtTo(data.dt_to)
                    setIsXml(data.is_xml)
                    setPartnerImage(data.imgPathLarge.replace('/300/', '/600/'))
                    setDescription(data.details)
                    setIsFree(data.is_free)
                    setPosition({
                        lat: data.lat,
                        lng: data.lng,
                    })
                    setDeeplink(data.deeplink)
                    setTarif(data.tarif)
                    setCname(data.c_name)
                    setCcity(data.city)
                    setCdetail(data.detail)
                    setCaddress(data.address)
                    // Page customisation
                    document.title = data.ev_name
                })
        }

    }, [])

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    /**
     *  Signaler un événement
     */
    const reportThisEvent = () => {
        reportAnEvent(eid)
            .then(response => {
                setDisableReport(true)
                console.log("disabling...")
            })
    }

    /**
     * Coupe de texte en conseravant le dernier mot entier
     * */
    function truncate(str, n, useWordBoundary) {
        if (str.length <= n) {
            return str
        }
        const subString = str.substr(0, n - 1); // the original check
        return (useWordBoundary
            ? subString.substr(0, subString.lastIndexOf(" "))
            : subString) + " (...)."
    }

    /**
     * Note :  format the date
     * @param _isXml
     * @param _dateFrom
     * @param _dateTo
     * @returns {string|boolean|number|string}
     */
    const formatDate = (_isXml, _dateFrom, _dateTo) => {
        if (_isXml === "1") {
            // Double check period (This is due to multiple date (not imported) for partners event)
            if (getFormattedDate(_dateFrom, 'after')) {
                return ("jusqu'au " + moment(_dateTo).format('HH:mm'))
            } else
                return (getFormattedDate(_dateFrom, 'start'))
        } else
            return (getFormattedDate(_dateFrom, 'start')
                + " jusqu'à " + moment(_dateTo).format('HH:mm'))
    }


    /**
     * Note : image management
     * @param _xml
     * @param _eid
     * @param _imageName
     * @param _partnerImageName
     * @param _updtAt
     */
    const imageIt = (_xml, _eid, _imageName = "", _partnerImageName = "", _updtAt) => {
        // Image management
        let picUrl = ""
        if (_xml === "1")
            picUrl = _partnerImageName
        else {
            if (_imageName)
                picUrl = BASE_URL + "img/events/" + _imageName + "?" + _updtAt
            else
                picUrl = BASE_URL + "img/events/cover-" + _eid + ".jpg?" + _updtAt
        }
        return picUrl
    }


    const imageItCompany = (_cid) => {
        // Image management
        let picUrl = ""
        if (_cid)
            picUrl = BASE_URL + "img/company/cover-" + _cid + ".jpg"

        return picUrl
    }

    const submit = () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1>êtes-vous certain.e?</h1>
                        <p>Vous allez signaler cet événement.</p>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onClose}>Non, c'est bon</Button>
                        <Button
                            onClick={() => {
                                reportThisEvent();
                                onClose();
                            }}
                        >
                            Oui, je le signale!
                        </Button>
                    </div>
                );
            }
        });
    };


    return (
        <div>
            <div className="sp-background" style={{
                backgroundImage: `url(${imageIt(xml, eid, imageName, partnerImage, updatedAt)}) `,
            }}></div>
            <div className="sp-div-card">
                <Grid container className={classes.root}>
                    <Grid item xs={12} sm={6}>
                        {(imageName || partnerImage) ?
                            (<Image
                                aspectRatio={10 / 16}
                                src={imageIt(xml, eid, imageName, partnerImage, updatedAt)}
                            />)
                            :
                            null
                        }
                    </Grid>

                    <Grid item xs={12} sm={6} className="background-card">
                        <Paper>
                            <p className={classes.priceZone}>
                                {isFree === true ?
                                    "Gratuit"
                                    :
                                    tarif > 0 ?
                                        (tarifPromo > 0 ? "Promo " + tarifPromo + " € au lieu de " + tarif : tarif) + " €"
                                        : ""
                                }
                            </p>
                            <Card>
                                <CardHeader
                                    className="title"
                                    // avatar={
                                    //     <Avatar aria-label="recipe" className={classes.avatar}>
                                    //         {isFree === true ?
                                    //             "Gratuit"
                                    //             :
                                    //             tarif > 0 ?
                                    //                 (tarifPromo > 0 ? tarifPromo : tarif) + " EUR"
                                    //                 : ""
                                    //         }
                                    //     </Avatar>
                                    // }
                                    action={
                                        <IconButton aria-label="settings"
                                                    onClick={() => {
                                                        window.close()//history.goBack
                                                    }}
                                        >
                                            <CloseIcon className="sp-rotate-detail-icon"/>
                                        </IconButton>
                                    }
                                    title={title}
                                    subheader={formatDate(xml, dtFrom, dtTo)}
                                />
                                <CardContent align="center">
                                    {(xml == 1 || xml === undefined)
                                        ? (
                                            <Grid item xs={12} sm={6} className="spacer-bt book-container">
                                                <a href={deeplink} className="book" target="_blank"
                                                   rel="noopener noreferrer"> Réserver maintenant !</a>
                                            </Grid>
                                        ) : null
                                    }
                                    <Typography variant="body2" color="textSecondary" component="p"
                                                style={{height: "100%", overflow: "auto"}}>
                                        {
                                            description && parse(description?.replaceAll("<br>", ""), {trim: true})
                                        }
                                    </Typography>
                                </CardContent>

                            </Card>
                        </Paper>
                    </Grid>
                </Grid>

                {/*############################################################*/}
                {/*##################   COMPANY CONTAINER    ##################*/}
                {/*############################################################*/}
                {
                    xml == 1
                        ?
                        (<Grid container className="company-container">
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography variant="h6" component="h4" className="company-title">
                                                {cname}
                                            </Typography>
                                            <Typography variant="caption" display="block" className="company-address">
                                                {caddress + " - " + ccity}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                                align={"center"}
                                                style={{marginTop: 20}}
                                            >
                                                {"premier acteur de la distribution de billets de spectacles," +
                                                    " d’événements sportifs et de parcs d’attractions en France."}
                                            </Typography>

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>)

                        :
                        (<Grid container className="company-container" direction="row">
                                <Grid item xs={12} sm={6} className="company-image">
                                    <Card className={classes.root}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography variant="h6" component="h4" className="company-title">
                                                    {cname} -
                                                    <Link
                                                        href={"tel:" + cphone}
                                                        size="small"
                                                        className="company-btn-call"
                                                    >
                                                        &nbsp;Appeler <PhoneIcon/>
                                                    </Link>
                                                </Typography>
                                                <Typography variant="caption" display="block"
                                                            className="company-address">
                                                    {caddress}
                                                </Typography>
                                                <Typography variant="caption" display="block" gutterBottom
                                                            align={"center"}
                                                            style={{marginTop: 20, fontSize: "0.95rem"}}
                                                >
                                                    {
                                                        (cdetail) && parse(cdetail?.replaceAll("<br>", ""), {trim: true})
                                                    }
                                                </Typography>

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                {(imageName || partnerImage) ?
                                    (<Grid item xs={12} sm={6} className="company-image">
                                            <Image
                                                aspectRatio={5 / 4}
                                                src={imageItCompany(cid)}
                                                //imageStyle={{borderRadius: 10, height: "50%", width: "50%"}}
                                                style={{borderRadius: 10, height: "50%",}}
                                                cover={true}
                                            />
                                        </Grid>
                                    )
                                    :
                                    null
                                }
                        </Grid>)
                }

                {/*############################################################*/}
                {/*##################   SHARING CONTAINER    ##################*/}
                {/*############################################################*/}
                <div className={classes.sharingRoot}>
                    <Grid container
                          className="sharing-container"
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                    >
                        <Grid item xs={1}>
                            <FacebookShareButton
                                url={"https://justnow.app/event/event.php?id=" + eid + "&type=" + isXml}
                                quote={title}
                            >
                                <FacebookIcon size={30} round/>
                            </FacebookShareButton>
                        </Grid>
                        <Grid item xs={1}>
                            <WhatsappShareButton
                                url={"https://justnow.app/event/event.php?id=" + eid + "&type=" + isXml}
                                quote={title}
                            >
                                <WhatsappIcon size={30} round/>
                            </WhatsappShareButton>
                        </Grid>
                        <Grid item xs={1}>
                            <TumblrShareButton
                                url={"https://justnow.app/event/event.php?id=" + eid + "&type=" + isXml}
                                quote={title}
                            >
                                <TumblrIcon size={30} round/>
                            </TumblrShareButton>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="report" onClick={() => submit()} >
                                <ReportIcon className={disableReport ? "dis-button" : "en-button"} fontSize="large" />
                            </IconButton>
                        </Grid>

                    </Grid>
                </div>

                {/*############################################################*/}
                {/*##################      MAP CONTAINER     ##################*/}
                {/*############################################################*/}
                {position !== undefined ?
                    <Grid container className="map-container">
                        <Grid item xs={12}>
                            <MiniMap Position={position}/>
                        </Grid>
                    </Grid>
                    : <p>Chargement de la carte...</p>
                }
            </div>
        </div>
    )
}
