import React from 'react'
import {Text, View, StyleSheet} from '@react-pdf/renderer'

const styles = StyleSheet.create({

    titleContainer: {
        position: 'absolute',
        bottom: 50,
        left: 0,
        right: 0,
    },
    reportBottomInfo: {
        fontSize: 8,
        textTransform: 'uppercase',
        textAlign: 'center'
    },

})


const InvoiceBottomInfo = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportBottomInfo}>
            JustNow {'\n'}
            Sarl JAMAIS VU ÇA{'\n'}
            7B Montée du Lutétia{'\n'}
            06500 Menton{'\n'}
            SIREN 517 871 729 - T.V.A Intracommunautaire FR 52 517871729 - R.C. Nice B 517 871 729
        </Text>
    </View>
)


export default InvoiceBottomInfo
