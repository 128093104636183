import axios from "axios"

import config from "../config"

const BASE_URL = config.BASE_URL
const apiX = axios.create({
    baseURL: BASE_URL
})

/**
 * Note : Retrieve the current list of business type set in the database
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getBusinessType = () => {
    return apiX
        .get("api/businessType/list")
}


