import moment from "moment"

export const locales = (_lang) => {

    switch (_lang) {
        case 'fr':
            moment.updateLocale('fr', {
                calendar: {
                    lastDay: '[Hier à] LT',
                    sameDay: "[Aujourd'hui à partir de] LT",
                    nextDay: "[demain à partir de] LT",
                    lastWeek: "[La semaine dernière à] LT",
                    nextWeek: "[ce] dddd [ à partir de] LT",
                    sameElse: "dddd DD MMM  [à partir de] HH:mm",
                }
            })
            break
        case 'it':
            moment.updateLocale('it', {
                calendar: {
                    lastDay: '[Ieri alle] LT',
                    sameDay: "[Oggi dalle] LT",
                    nextDay: "[Domani dalle] LT",
                    lastWeek: "[La settimana scorsa dalle] LT",
                    //nextWeek: "[Questo] dddd [dalle] LT",
                    nextWeek: "Il dddd [dalle] LT",
                    sameElse: "dddd DD MMM  [dalle] HH:mm",
                }
            })
            break
        case 'en':
            moment.updateLocale('en', {
                calendar: {
                    lastDay: '[Yesterday à] LT',
                    sameDay: "[Today from] LT",
                    nextDay: "[Tomorrow  from] LT",
                    lastWeek: "[Last week from] LT",
                    nextWeek: "[This] dddd [from] LT",
                    sameElse: "dddd DD MMM  [from] HH:mm",
                }
            })
            break
        case 'ru':
            moment.updateLocale('ru', {
                calendar: {
                    lastDay: '[Вчера в] LT',
                    sameDay: "[Cегодня C] LT",
                    nextDay: "[завтра C] LT",
                    lastWeek: "[На прошлой неделе C] LT",
                    nextWeek: "[это] dddd [C] LT",
                    sameElse: "dddd DD MMM  [C] HH:mm",
                }
            })
            break
        case 'zh-cn':
            moment.updateLocale('zh-cn', {
                calendar: {
                    lastDay: '[昨天] LT',
                    sameDay: "[今天 从] LT",
                    nextDay: "[明天  从] LT",
                    lastWeek: "[上个星期 从] LT",
                    nextWeek: "[这] dddd [从] LT",
                    sameElse: "dddd DD MMM  [从] HH:mm",
                }
            })
            break
    }


}
