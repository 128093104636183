import React from 'react'
import Box from "@material-ui/core/Box"
import Billing from "./Billing"


export default function BillingIndex(props) {

    return (

                <Billing />

    )
}

