import React, { Fragment } from 'react'
import {Text, View, StyleSheet } from '@react-pdf/renderer'
import moment from "moment"

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end',
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        width: 60,
        fontSize: 12,
        fontStyle: 'bold',
        textAlign: 'right',
    },
    label: {
        width: 60,
        textAlign: 'left'
    }

})


const InvoiceNo = ({invoice}) => (
    <Fragment>
        <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>Facture :</Text>
            <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Date : </Text>
            <Text style={styles.invoiceDate}>{moment(invoice.trans_date).format('DD/MM/YYYY')}</Text>
        </View >
    </Fragment>
)

export default InvoiceNo
