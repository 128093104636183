import React from 'react'
import { Zoom } from "react-awesome-reveal"

// Style
import './moveOn.css'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Container from "react-bootstrap/Container"

export default function MoveOn() {

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className='moveon-container'>
                        <Zoom>
                            <Image src={require('../../assets/app_name.png')} fluid/>
                        </Zoom>
                        <h1 className='moveon-title'>Rendez-vous sur nos applications pour créer et gérer votre
                            établissement et vos publications.</h1>
                        <div className='moveon-store-container '>
                            <ul className="list-inline moveon-ul-container">
                                <li>
                                    <a target="_blank"
                                       href='https://play.google.com/store/apps/details?id=com.ekrak&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                                        className="img-responsive" width="200em" alt='Disponible sur Google Play'
                                        src={require('../../assets/gl.png')}/></a>

                                </li>
                                <li>
                                    <a target="_blank"
                                       href='https://itunes.apple.com/us/app/ekrak/id1217014035?l=fr&ls=1&mt=8'><img
                                        className="img-responsive" width="200em" alt='Disponible sur Google Play'
                                        src={require('../../assets/ap.png')}/></a>

                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}



