import React from 'react'
import {Page, Document, Image, StyleSheet, PDFViewer} from '@react-pdf/renderer'
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import InvoiceBottomInfo from "./InvoiceBottomInfo"

const LOGO = require('../../../assets/app_name.png')


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 150,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
})

const Invoice = ({invoice}) => (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image style={styles.logo} src={LOGO}/>
                <InvoiceTitle title='Facture'/>
                <InvoiceNo invoice={invoice}/>
                <BillTo invoice={invoice}/>
                <InvoiceItemsTable invoice={invoice}/>
                <InvoiceThankYouMsg items={invoice.items}/>
                <InvoiceBottomInfo/>
            </Page>
        </Document>
)

export default Invoice
