import React, {useEffect, useState} from "react";
import {Button, Container, Link as Linked} from "react-floating-action-button";
import "./floatingButton.styles.css"
import {userService} from "../../_services";


export default function FloatingButton(props) {

    const [ isConnected, setIsConnected ] = useState(userService.getUserConnexionStatus())

    useEffect(() => {
        setIsConnected(userService.getUserConnexionStatus())
    }, )

    return (
        <div>
            {
                isConnected ?
                    ( <Container className='fab-container-sp'>
                            <Linked
                                href="/dashboard"
                                tooltip="Tableau de bord"
                                icon="fa fa-tachometer"
                                className="fab-item btn btn-link btn-lg text-white"/>
                            <Linked href="/moncomptepro"
                                    tooltip="Mon compte"
                                    icon="fa fa-user"
                                    className="fab-item btn btn-link btn-lg text-white"/>
                            <Linked href="/adminpro"
                                    tooltip="Ajouter un événement"
                                    icon="fa fa-plus"
                                    className="fab-item btn btn-link btn-lg text-white"/>

                            <Button
                                tooltip="Options"
                                icon="fa fa-plus"
                                rotate={true}
                                onClick={() => console.log("ok")}/>
                        </Container> )
                        :
                        null
            }
        </div>

)
}
