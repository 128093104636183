// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import {registerFCMTokenToTopic} from "./api/eventActions"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyANn4TxBdxBhfKKWKRrnRizPeml3paZo2I",
    authDomain: "justnow-122e2.firebaseapp.com",
    projectId: "justnow-122e2",
    storageBucket: "justnow-122e2.appspot.com",
    messagingSenderId: "845246769616",
    appId: "1:845246769616:web:78e30d76e4b7a5504471d5",
    measurementId: "G-9T4KF33WSZ"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const getTheToken = () => {
    return getToken(messaging,
        {vapidKey:"BKDHJnH1s321L0AOUcvO7nVQj9vRscU4pobijsTEAAMGBtn43eQmECGAgGQZCCrDCMD6T1cksw1L1YYNA62B19g"})
        .then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken)
            registerFCMTokenToTopic(currentToken, 'allweb').then(r =>
            console.log("enregistrement de votre navigateur sur Firebase")
            )
            return true
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            return false
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    })
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
