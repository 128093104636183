import React, {useState} from 'react'
import Container from "react-bootstrap/Container"
import Spinner from "../spinner/Spinner"
import ParticlesBg from "particles-bg"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {sendConfirmationForDeleteAccount} from "../../api/userActions"

const strings = {
    title: "Supprimer mon compte",
    message: "Veuillez saisir votre email pour supprimer votre compte",
    button: "Supprimer mon compte",
}

export function DeleteMe() {
    const [email, setEmail] = useState("")
    const [isValid, setIsValid] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(undefined)
    const [error, setError] = useState(false)
    const [msgError, setMsgError] = useState("Une erreur est survenue :(, veuillez réessayer.")

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email)
    }

    const handleInputChange = (event) => {
        setEmail(event.target.value);
        setIsValid(validateEmail(event.target.value))
    }

    /**
     * Note : Handle submit calling the API to launch the delete process (if email exists in DB)
     * @param e
     */
    const handleSubmit = (e) => {
        e.preventDefault()
        if (email && isValid)
            sendConfirmationForDeleteAccount(email)
                .then(response => {
                    console.log(response)
                    if (response.status === 200 && response.data.status === 200) {
                        setSuccess(true)
                        setError(false)
                    } else {
                        setSuccess(false)
                        setError(true)
                    }
                })
    }

    return (
        <Container>
            <Spinner isLoading={loading} textToDisplay="Traitement de vos informations..."/>
            <div style={loading ? styles.hiddenMain : styles.main}>
                <ParticlesBg type="circle" bg={true}/>
                <Row className="justify-content-md-center">
                    <Col xs={12} xl={12}>
                        <form className="card" style={styles.form} onSubmit={handleSubmit}>
                            <h3 style={success ? styles.successTitle : styles.title}>
                                {strings.title}
                            </h3>
                            {
                                error
                                    ? (
                                        <p style={styles.confirmMsgErr}>
                                            {msgError}
                                        </p>
                                    )
                                    : null
                            }

                            <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                <label htmlFor="username">email</label>
                                <div className="input-group ">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                                    </div>
                                    <input type="email"
                                           className={`${'form-control'} ${submitted && !email && "form-control is-invalid"}`}
                                           id="username"
                                           value={email}
                                           onChange={handleInputChange}
                                           required
                                    />
                                </div>
                                {!isValid && <p>Veuillez entrer un email valide.</p>}
                                {success && <p><br/>Veuillez vérifier votre email <br />
                                    pour confirmer la suppression de votre compte.</p>}

                            </div>

                            <button type="submit" className="btn btn-primary btn-block sp-btn" onSubmit={handleSubmit}>
                                {strings.button}
                            </button>
                        </form>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

const styles = {
    main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "rgba(111,164,218,0.86)",
        // backgroundImage: `url(${Background})`,
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'noRepeat',
        // background: `url(${Background}) center no-repeat, linear-gradient(135deg, #50A684 40%, #115E67 90%)`,
        // background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url(${Background}) no-repeat`,
        height: '90vh',
        width: '100%',
    },
    hiddenMain: {
        display: "none",
    },
    form: {
        backgroundColor: "rgba(75,83,91,0.7)",
        boxShadow: "0 0 20px 2px rgba(0, 0, 0, 0.4)",
        padding: '10px',
    },
    overlay: {
        background: `linear-gradient(to top, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.65) 100%)`,
    },
    title: {
        color: 'white',
        textAlign: 'center',
    },
    successTitle: {
        color: 'green',
        textAlign: 'center',
    },
    confirmMsg: {
        color: 'rgb(57,207,36)',
        textAlign: 'center',
    },
    confirmMsgErr: {
        color: 'rgb(101,5,5)',
        textAlign: 'center',
    }
}

