import React, {useEffect, useState} from "react"

// Private Component
import config from "../../config"
import Spinner from "../spinner/Spinner"

// API Component
import {newPasswordRequest} from "../../api/userActions"

// Style
import "../auth/login/login.css"
import Container from "react-bootstrap/Container"
import ParticlesBg from "particles-bg"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {userActions} from "../../_actions";
import TextField from "@material-ui/core/TextField";

const BASE_URL = config.BASE_URL


const styles = {
    main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "rgba(111,164,218,0.86)",
        // backgroundImage: `url(${Background})`,
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'noRepeat',
        //background: `url(${Background}) center no-repeat, linear-gradient(135deg, #50A684 40%, #115E67 90%)`,
        //background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url(${Background}) no-repeat`,
        height: '90vh',
    },
    hiddenMain: {
        display: "none",
    },
    form: {
        backgroundColor: "rgba(75,83,91,0.7)",
        boxShadow: "0 0 20px 2px rgba(0, 0, 0, 0.4)",
        width: '100%',
        padding: '10px',
    },
    overlay: {
        background: `linear-gradient(to top, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.65) 100%)`,
    },
    title: {
        color: 'white',
        textAlign: 'center',
    },
    successTitle: {
        color: 'green',
        textAlign: 'center',
    },
    confirmMsg: {
        color: 'rgb(57,207,36)',
        textAlign: 'center',
    },
    confirmMsgErr: {
        color: 'rgb(101,5,5)',
        textAlign: 'center',
    }
}

export default function ForgottenEmail() {
    const [email, setEmail] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(undefined)
    const [message, setMessage] = useState("On va vous aider !")
    const [error, setError] = useState(false)
    const [msgError, setMsgError] = useState("Une erreur est survenue :(, veuillez réessayer.")


    const onChange = e => {
        console.log(e.target.value)
        setEmail(e.target.value)
    }

    const handleSubmit = e => {
        setLoading(true)
        e.preventDefault()
        //const _login = this.state._email;
        const userData = {
            email: email,
        }
        setSubmitted(true)
        console.log(userData)

        if (email) {
            newPasswordRequest(email)
                .then(response => {
                    console.log(response)
                    if (response.status === 200) {
                        setSuccess(true)
                        setMessage(response.data)
                    } else if (response.status === 207) {
                        setSuccess(false)
                        setError(true)
                        setMsgError(response.data)
                    } else {
                        setSuccess(false)
                        setError(true)
                    }
                })
                .catch(err => {
                    setSuccess(false)
                    setError(true)
                })
                .finally(() => setLoading(false)
                )
        }
    }

    return (
        <Container>
            <Spinner isLoading={loading} textToDisplay="Traitement de vos informations..."/>

            <div style={loading ? styles.hiddenMain : styles.main}>
                <ParticlesBg type="cobweb" num={50} bg={true}/>
                <Row className="justify-content-md-center">
                    <Col xs={12}>
                        <form className="card" style={styles.form} onSubmit={handleSubmit}>
                            <h3 style={success ? styles.successTitle : styles.title}>
                                {message}
                            </h3>
                            {
                                error
                                    ? (
                                        <p style={styles.confirmMsgErr}>
                                            { msgError }
                                        </p>
                                    )
                                    : null
                            }

                            <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                <label htmlFor="username">email</label>
                                <div className="input-group ">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                                    </div>
                                    <input type="email"
                                           className={`${'form-control'} ${submitted && !email && "form-control is-invalid"}`}
                                           id="username"
                                           value={email}
                                           onChange={onChange}
                                           required
                                        // helperText={errors.email}

                                    />
                                    {submitted && !email &&
                                    <div className="invalid-feedback">Veuillez saisir un email</div>
                                    }
                                </div>

                            </div>

                            <button type="submit" className="btn btn-primary btn-block sp-btn">Recevoir un nouveau Mot
                                de Passe
                            </button>
                        </form>
                    </Col>
                </Row>
            </div>
        </Container>
    )


}
