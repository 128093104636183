import axios from "axios";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING
} from "./types";
//import Cookies from 'js-cookie';

// Private Component
import setAuthToken from "../utils/setAuthToken";
import config from "../config"


const apiX = axios.create({
    baseURL: config.BASE_URL
})


/**
 * Note : Check email of pro user
 */
export const confirmEmail =  async (_code, _email) => {
    return await apiX
        .get("/api/email/checkconfirmation/" + _code + "/" + _email)
}


/**
 * Note : Check email of end user
 */
export const confirmEUEmail =  async (_code, _email) => {
    return await apiX
        .get("/api/email/checkconfirmationeu/" + _code + "/" + _email)
}

/**
 *
 */
export const isValidUserAndCompany = async ( _cid ) => {
    return await apiX
        .get("/api/users/isvalid/" + _cid)
}

/**
 * Check User valid to post new event
 * @param userData
 * @returns {Promise<AxiosResponse<any>>}
 */
export const registerUser = async (userData) =>  {
    return await apiX
        .post("/api/users/register",  userData)
        .then( response => response)
        .catch(err =>
             err
        );
};

// // Register User with redux use
// export const registerUser = (userData, history) => dispatch => {
//     apiX
//         .post("/api/users/register", userData)
//         .then(res => history.push("/login")) // re-direct to login on successful register
//         .catch(err =>
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: err.response.data
//             })
//         );
// };
// Login - get user token
export const loginUser = userData => dispatch => {
   apiX
        .post("api/users/login", userData, )
        .then(res => {
            const { token } = res.data;
            //Cookies.set("tokenPro", token)
            //Cookies.set("keepSignIn", 1)
            localStorage.setItem("jwtToken", token);
            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            // Set current user
            console.log(decoded)
            dispatch(setCurrentUser(decoded));

        })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                })
            }
        );
//     axios
//         .post("/api/users/login", userData)
//         .then(res => {
//             // Save to localStorage
// // Set token to localStorage
//             const { token } = res.data;
//             localStorage.setItem("jwtToken", token);
//             // Set token to Auth header
//             setAuthToken(token);
//             // Decode token to get user data
//             const decoded = jwt_decode(token);
//             // Set current user
//             dispatch(setCurrentUser(decoded));
//         })
//         .catch(err =>
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: err.response.data
//             })
//         );

};
// Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};
// User loading
export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};
// Log user out
export const logoutUser = () => dispatch => {
    // Remove token from local storage
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to empty object {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};
