// export default [
//     {
//         featureType: "all",
//         elementType: "geometry.fill",
//         stylers: [
//             {
//                 weight: "2.00",
//             },
//         ],
//     },
//     {
//         featureType: "all",
//         elementType: "geometry.stroke",
//         stylers: [
//             {
//                 color: "#9c9c9c",
//             },
//         ],
//     },
//     {
//         featureType: "all",
//         elementType: "labels.text",
//         stylers: [
//             {
//                 visibility: "on",
//             },
//         ],
//     },
//     {
//         featureType: "landscape",
//         elementType: "all",
//         stylers: [
//             {
//                 color: "#f2f2f2",
//             },
//         ],
//     },
//     {
//         featureType: "landscape",
//         elementType: "geometry.fill",
//         stylers: [
//             {
//                 color: "#ffffff",
//             },
//         ],
//     },
//     {
//         featureType: "landscape.man_made",
//         elementType: "geometry.fill",
//         stylers: [
//             {
//                 color: "#ffffff",
//             },
//         ],
//     },
//     {
//         featureType: "poi",
//         elementType: "all",
//         stylers: [
//             {
//                 visibility: "off",
//             },
//         ],
//     },
//     {
//         featureType: "road",
//         elementType: "all",
//         stylers: [
//             {
//                 saturation: -100,
//             },
//             {
//                 lightness: 45,
//             },
//         ],
//     },
//     {
//         featureType: "road",
//         elementType: "geometry.fill",
//         stylers: [
//             {
//                 color: "#eeeeee",
//             },
//         ],
//     },
//     {
//         featureType: "road",
//         elementType: "labels.text.fill",
//         stylers: [
//             {
//                 color: "#7b7b7b",
//             },
//         ],
//     },
//     {
//         featureType: "road",
//         elementType: "labels.text.stroke",
//         stylers: [
//             {
//                 color: "#ffffff",
//             },
//         ],
//     },
//     {
//         featureType: "road.highway",
//         elementType: "all",
//         stylers: [
//             {
//                 visibility: "simplified",
//             },
//         ],
//     },
//     {
//         featureType: "road.arterial",
//         elementType: "labels.icon",
//         stylers: [
//             {
//                 visibility: "off",
//             },
//         ],
//     },
//     {
//         featureType: "transit",
//         elementType: "all",
//         stylers: [
//             {
//                 visibility: "off",
//             },
//         ],
//     },
//     {
//         featureType: "water",
//         elementType: "all",
//         stylers: [
//             {
//                 color: "#46bcec",
//             },
//             {
//                 visibility: "on",
//             },
//         ],
//     },
//     {
//         featureType: "water",
//         elementType: "geometry.fill",
//         stylers: [
//             {
//                 color: "#77a198",
//             },
//         ],
//     },
//     {
//         featureType: "water",
//         elementType: "labels.text.fill",
//         stylers: [
//             {
//                 color: "#070707",
//             },
//         ],
//     },
//     {
//         featureType: "water",
//         elementType: "labels.text.stroke",
//         stylers: [
//             {
//                 color: "#ffffff",
//             },
//         ],
//     },
// ];

//
// export default [
//     {
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#ebe3cd"
//             }
//         ]
//     },
//     {
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "color": "#523735"
//             }
//         ]
//     },
//     {
//         "elementType": "labels.text.stroke",
//         "stylers": [
//             {
//                 "color": "#f5f1e6"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "color": "#c9b2a6"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.land_parcel",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.land_parcel",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "color": "#dcd2be"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.land_parcel",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "color": "#ae9e90"
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.neighborhood",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "landscape.natural",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#dfd2ae"
//             }
//         ]
//     },
//     {
//         "featureType": "poi",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#dfd2ae"
//             }
//         ]
//     },
//     {
//         "featureType": "poi",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "poi",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "color": "#93817c"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.business",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.park",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#a5b076"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.park",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "poi.park",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "color": "#447530"
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#f5f1e6"
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#fdfcf8"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#f8c967"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "color": "#e9bc62"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway.controlled_access",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#e98d58"
//             }
//         ]
//     },
//     {
//         "featureType": "road.highway.controlled_access",
//         "elementType": "geometry.stroke",
//         "stylers": [
//             {
//                 "color": "#db8555"
//             }
//         ]
//     },
//     {
//         "featureType": "road.local",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "road.local",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "color": "#806b63"
//             }
//         ]
//     },
//     {
//         "featureType": "transit.line",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#dfd2ae"
//             }
//         ]
//     },
//     {
//         "featureType": "transit.line",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "color": "#8f7d77"
//             }
//         ]
//     },
//     {
//         "featureType": "transit.line",
//         "elementType": "labels.text.stroke",
//         "stylers": [
//             {
//                 "color": "#ebe3cd"
//             }
//         ]
//     },
//     {
//         "featureType": "transit.station",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "color": "#dfd2ae"
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "elementType": "geometry.fill",
//         "stylers": [
//             {
//                 "color": "#5791a0"
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "elementType": "labels.text",
//         "stylers": [
//             {
//                 "visibility": "off"
//             }
//         ]
//     },
//     {
//         "featureType": "water",
//         "elementType": "labels.text.fill",
//         "stylers": [
//             {
//                 "color": "#92998d"
//             }
//         ]
//     }
// ]

export default [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ebe3cd"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#523735"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#f5f1e6"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#c9b2a6"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#dcd2be"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ae9e90"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dfd2ae"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dfd2ae"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#93817c"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#a5b076"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#447530"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f1e6"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#fdfcf8"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f8c967"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#e9bc62"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e98d58"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#db8555"
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#806b63"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dfd2ae"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8f7d77"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ebe3cd"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dfd2ae"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#5791a0"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#92998d"
            }
        ]
    }
]
