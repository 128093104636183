import React, {useCallback, useEffect, useRef, useState} from "react"
import parse from 'html-react-parser'
import {
    GoogleMap, Marker, MarkerClusterer,
    InfoWindow, useJsApiLoader, Autocomplete
} from "@react-google-maps/api"
import SlidingPane from "react-sliding-pane"
import {Checkbox} from 'pretty-checkbox-react'
import '@djthoms/pretty-checkbox'
import MapIcon from '@material-ui/icons/Map'
import CheckIcon from '@material-ui/icons/Check'
import ExploreIcon from '@material-ui/icons/Explore'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import moment from "moment"
import {Divider, FormHelperText, NativeSelect} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import FormControl from "@material-ui/core/FormControl"
import {makeStyles} from "@material-ui/core/styles"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"

// Private Component
import Tools from './overlayTools/Tools'
import config from "../../config"
import {locales} from '../../utils/locale/MomentLocale'
import {getFormattedDate, parseBool, readableDistance, readableTarif} from "../../utils/functions"
// import {getToken} from "firebase/messaging"

// Style
import mapStyles from "./mapStyles"
import "./maps.css"
import "./animated.css"
import Col from "react-bootstrap/Col"
import {Link} from "react-router-dom"
import Row from "react-bootstrap/Row"
import Image from "react-bootstrap/Image"
import bgImage from "../../assets/premium-background-smooth-dark.jpg"
import "react-sliding-pane/dist/react-sliding-pane.css"
import {wait} from "@testing-library/user-event/dist/utils"
import {Zoom} from "react-awesome-reveal"


const useStyles = makeStyles((theme) => ({
    formControl: {
        display: "flex",
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100vw",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const libraries = ["places"]
const mapContainerStyle = {
    height: "100vh",
    width: "100vw",
}
const options = {
    styles: mapStyles,
    // disableDefaultUI: true,
    zoomControl: true,
}


export default function App(props) {
    const classes = useStyles()
    locales('fr')
    const {isLoaded, loadError} = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    })
    const [openPanel, setOpenPanel] = useState(false)
    const [showCluster, setShowCluster] = useState(parseBool(localStorage.getItem('showCluster')) ?? true)
    const [memoPlaceList, setMemoPlaceList] = useState(JSON.parse(localStorage.getItem('memoPlace')) ?? [])
    const [markers, setMarkers] = useState()
    const [selected, setSelected] = useState(null)
    const [styleMap, setStyleMap] = useState(mapContainerStyle)
    const [userPos, setUserPos] = useState()
    const [filterList, setFilterList] = useState(props.filterList)
    const [filterActive, setFilterActive] = useState(props.filterStatus)
    const [mapRef, setMapRef] = useState(null)
    const [showPartner, setShowPartner] = useState(parseBool(localStorage.getItem('showPartner')) ?? false)

    const [autoRef, setAutoRef] = useState(null)
    const checkClusterRef = useRef(null)
    // Animation Stuff
    // /**
    //  * Note : loading map
    //  * @param map
    //  */
    // const handleOnLoad = useCallback(function callback(map) {
    //     if (map) {
    //         setMapRef(map)
    //         console.log("HANDLE on LOAD : ", map.current.getCenter())
    //         askGeoloc()
    //     }
    // }, [])

    // const [isTokenFound, setTokenFound] = useState(false)

    // useEffect(() => {
    //     getToken(setTokenFound)
    //
    // }, [])


    const onLoad = map => {
        if (map) {
            setMapRef(map)
            askGeoloc()
        }
    }

    /**
     * Note :  Ask navigator user location
     * @returns {Promise<void>}
     */
    async function askGeoloc() {
        await navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserPos({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
                // Remember local user position
                localStorage.setItem('lat', position.coords.latitude.toString())
                localStorage.setItem('long', position.coords.longitude.toString())
            },
            () => null
        )
    }

    /**
     * Note loading marker on map
     */
    useEffect(() => {
        setMarkers(props.eventMarkers)
    }, [props.eventMarkers])

    const panTo = useCallback(({lat, lng}) => {
        mapRef.current.panTo({lat, lng})
        mapRef.current.setZoom(5)
    }, [])


    if (loadError) return "Error"
    if (!isLoaded) return "Loading..."

    function reduceMySize() {
        setStyleMap({
            transition: `height 1000ms ease-in-out`,
            height: "40vh",
            width: "100vw",
        })
    }

    function maximizeMySize() {
        setStyleMap({
            transition: `height 800ms ease-in-out`,
            height: "100vh",
            width: "100vw",
        })
    }

    const iconIAmHere = {
        url: require('../../assets/iamhere.png'),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 15),
        scaledSize: new window.google.maps.Size(50, 50),
    }


    /**
     *
     * @param _key
     */
    const handleChangeFilter = (_key) => {
        let index = filterList.findIndex(x => x.key === _key)
        if (index === -1)
            return false
        else {
            let newSet = [...filterList]
            newSet[index] = {...newSet[index], ...{raised: !newSet[index].raised}}
            // newSet.map((item) => {
            //     if (item.raised === item.raised)
            //         console.log(item)
            // }
            // setBusinessType(newSet)
            // Calling parent function
            if (newSet) {
                setFilterList(newSet)
                const count = newSet.reduce((nb, item) => item.raised === true ? ++nb : nb, 0)
                if (count && count > 0) {
                    setFilterActive(true)
                    props.filterOp(newSet)
                    props.filterStatusOp(true)
                } else {
                    setFilterActive(false)
                    props.filterOp(newSet)
                    props.filterStatusOp(false)
                }
            }
        }
    }


    const handleOnLoadAutoComplete = (auto) => {
        setAutoRef(auto)
    }


    /**
     * Note :  format the date
     * @param _isXml
     * @param _dateFrom
     * @param _dateTo
     * @returns {string|boolean|number|string}
     */
    const formatDate = (_isXml, _dateFrom, _dateTo) => {
        if (_isXml === "1") {
            // Double check period (This is due to multiple date (not imported) for partners event)
            if (getFormattedDate(_dateFrom, 'after')) {
                return ("jusqu'au " + moment(_dateTo).format('HH:mm'))
            } else
                return (getFormattedDate(_dateFrom, 'start'))
        } else
            return (getFormattedDate(_dateFrom, 'start')
                + " jusqu'à " + moment(_dateTo).format('HH:mm'))
    }

    /***
     * Note : Change the visualization of marker on the map and local store the value for the next visit
     */
    const handleChangeClusterMode = () => {
        localStorage.setItem("showCluster", !showCluster)
        setShowCluster(!showCluster)
    }

    const handleChangePartnerMode = () => {
        props.partnersOp(!showPartner)
        localStorage.setItem("showPartner", !showPartner)
        setShowPartner(!showPartner)
    }


    /**
     * Note : saving a custom place
     */
    const handleAddMemoPlace = () => {
        if (autoRef) {
            let newPlace = {
                name: autoRef.getPlace().address_components[0].long_name + ', ' + autoRef.getPlace().name,
                lat: autoRef.getPlace().geometry.location.lat(),
                lng: autoRef.getPlace().geometry.location.lng(),
            }
            setMemoPlaceList(old => [...old, newPlace])
            // Temp fall array
            let st = memoPlaceList
            st.push(newPlace)
            localStorage.setItem("memoPlace", JSON.stringify(st))
        }
    }

    /**
     * Note : Delete the corresponding place based on the active userpos
     */
    const handleDeleteMemoPlaceItem = () => {
        if (memoPlaceList.length >= 1) {
            const filteredPlace = memoPlaceList.filter((place) => {
                if (place.lat !== userPos.lat && place.lng !== userPos.lng) {
                    return place
                }
            })
            if (filteredPlace) {
                setMemoPlaceList(filteredPlace)
                localStorage.setItem("memoPlace", JSON.stringify(filteredPlace))
            }
        }
    }

    const handleResetFilter = () => {
        let resetIt = filterList.map(item => {
            return {...item, raised: false}
        })
        setFilterList(resetIt)
        setFilterActive(false)
        props.filterOp(resetIt)
        props.filterStatusOp(false)
        setOpenPanel(false)
    }


    /**
     * Note : Change map position when Google place is selected
     */
    const handleChangePosition = () => {
        if (autoRef) {
            setUserPos({
                lat: autoRef.getPlace().geometry.location.lat(),
                lng: autoRef.getPlace().geometry.location.lng(),
            })
            handleMoveTheMap()
        }
        //setOpenPanel(false)}
    }


    /**
     * Note : Change map position when user list place is selected
     */
    const handleChangePositionFromUserList = (e) => {
        let st = e.target.value.split(',')
        if (st) {
            setUserPos({
                lat: parseFloat(st[0]),
                lng: parseFloat(st[1]),
            })
            wait(1000).then(r => handleMoveTheMap(true))

        }
        // set Active value
        //setOpenPanel(false)
    }

    /**
     * Note : fire when the map is moved
     * Add : Récupération des noms de l'object reçu par getBounds (ils ont changé durant le développement)
     */
    const handleMoveTheMap = (_reset = false) => {
        let lowBoundsChar = Object.keys(mapRef.getBounds())[0]
        let highBoundsChar = Object.keys(mapRef.getBounds())[1]
        let lowBounds = eval("mapRef.getBounds()." + lowBoundsChar + ".lo") + " " + eval(" mapRef.getBounds()." + highBoundsChar + ".lo")
        let highBounds = eval("mapRef.getBounds()." + lowBoundsChar + ".hi") + " " + eval(" mapRef.getBounds()." + highBoundsChar + ".hi")

        let region = mapRef.getCenter()
        // let cleanedBoundaries = mapRef.getBounds().eb.lo + " " + mapRef.getBounds().Ha.lo + "," +
        //     mapRef.getBounds().eb.hi + " " + mapRef.getBounds().Ha.hi
        let cleanedBoundaries = lowBounds + ',' + highBounds

        if (region && cleanedBoundaries)
            props.move(cleanedBoundaries, region, _reset)
    }

    return isLoaded ? (
        <div>
            <SlidingPane
                closeIcon={<div>X</div>}
                isOpen={openPanel}
                title="Mes paramètres"
                from="right"
                width="25vw"
                onRequestClose={() => setOpenPanel(false)}
                className="side-panel-container"
            >
                <>
                    <p> Visualisez Les événements de notre partenaire FNAC Spectacles </p>
                    <Checkbox
                        shape="round"
                        icon={<CheckIcon className="svg" data-type="svg"/>}
                        checked={showPartner}
                        // style={{display: "flex"}}
                        ref={checkClusterRef}
                        animation="jelly"
                        color="success"
                        onChange={handleChangePartnerMode}
                    > <span style={{marginLeft: 10}}>Afficher FNAC Spectacles</span>
                    </Checkbox>
                </>
                <Divider className="divider-side-panel"/>
                <>
                    <p> Visualisez plus rapidement les zones actives sur votre carte </p>
                    <Checkbox
                        shape="round"
                        icon={<CheckIcon className="svg" data-type="svg"/>}
                        checked={showCluster}
                        // style={{display: "flex"}}
                        ref={checkClusterRef}
                        animation="jelly"
                        color="success"
                        onChange={handleChangeClusterMode}
                    > <span style={{marginLeft: 10}}>Grouper les événements</span>
                    </Checkbox>
                </>
                <Divider className="divider-side-panel"/>
                <p>Saisissez ci-dessous une destination pour recentrer la carte et le flux (enregistrez votre choix à
                    l'aide du bouton +) </p>
                <div style={{display: "inline-flex", alignItems: "center", width: "100%"}}>
                    <MapIcon style={{color: "#435244"}} fontSize="large"/>
                    <Autocomplete
                        onLoad={handleOnLoadAutoComplete}
                        onPlaceChanged={handleChangePosition}
                        className="autoComplete-div"
                    >
                        <input
                            type="text"
                            placeholder="Changez de localité"
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `100%`,
                                height: `32px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                // position: "absolute",
                                // left: "50%",
                                marginLeft: "10px"
                            }}
                        />
                    </Autocomplete>
                    <IconButton className="sp-button-add-place"
                                onClick={handleAddMemoPlace}>
                        <AddCircleOutlineIcon className="sp-icon-add-place"/>
                    </IconButton>

                </div>
                <Divider className="divider-side-panel"/>
                <div>
                    <p>Ou choisissez une destination parmi vos éléments suavegardés </p>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}>

                        <ExploreIcon style={{color: "#435244"}} fontSize="large"/>
                        <FormControl className={classes.formControl}>
                            <NativeSelect
                                onChange={handleChangePositionFromUserList}
                                name="age"
                                className={classes.selectEmpty}
                                inputProps={{'aria-label': 'age'}}
                            >
                                <option key={-1} value={-1}>Aucun lieu choisi</option>
                                {memoPlaceList.length >= 1 && memoPlaceList.map((place, index) => {
                                    if (place.name && place.name.length > 1)
                                        return <option key={index}
                                                       value={place.lat + "," + place.lng}>{place.name} </option>
                                })
                                }
                            </NativeSelect>
                            <FormHelperText>Vos lieux favoris enregistrés</FormHelperText>
                            <div style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                marginTop: 10
                            }}>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    className={classes.button}
                                    // startIcon={<DeleteIcon color="action"/>}
                                    onClick={handleDeleteMemoPlaceItem}
                                >
                                    Supprimer ce lieu
                                </Button>
                            </div>
                        </FormControl>
                    </div>
                </div>
                <Divider className="divider-side-panel"/>

                <p> Utilisez les filtres ci-dessous pour trouver plus rapidement ce que vous cherchez</p>

                <div className="side-panel-business-type-container">
                    {filterList &&
                        filterList.map((type) => {
                            return (
                                <IconButton key={type.key} className="sp-button"
                                            onClick={() => handleChangeFilter(type.key)}>
                                    <i
                                        className={type.raised ? ["fa fa-" + type.icon + " fa-lg " + " sp-button-active"]
                                            : ["fa fa-" + type.icon + " fa-lg " + " sp-button"]}>
                                    </i>
                                </IconButton>
                            )
                        })
                    }
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginTop: 20,
                }}>
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        className={classes.button}
                        // startIcon={<DeleteIcon color="action"/>}
                        onClick={handleResetFilter}
                    >
                        Effacer les filtres
                    </Button>
                </div>
            </SlidingPane>
            <GoogleMap
                options={options}
                onLoad={onLoad}
                id="mapX"
                mapContainerStyle={styleMap}
                zoom={props.zoom}
                center={userPos}
                onDragEnd={handleMoveTheMap}
                // onZoomChanged={console.log("zoom changed")}
                // onIdle={() => {
                //     console.log("on IDLE")
                // }}
            >
                <Tools reduceMySize={() => reduceMySize()} maximizeMySize={() => maximizeMySize()}
                       openPanel={() => setOpenPanel(!openPanel)}/>

                {filterList && showCluster ?
                    <MarkerClusterer>
                        {(clusterer) =>
                            markers.map((marker, index) => {
                                // var iconX = null
                                // if (marker.is_xml === '1') {
                                //     iconX = {
                                //         url: require('../../assets/fnac.png'),
                                //         origin: new window.google.maps.Point(0, 0),
                                //         anchor: new window.google.maps.Point(15, 15),
                                //         scaledSize: new window.google.maps.Size(30, 30),
                                //     }
                                // } else {
                                //     iconX = {
                                //         url: require('../../assets/j.png'),
                                //         origin: new window.google.maps.Point(0, 0),
                                //         anchor: new window.google.maps.Point(15, 15),
                                //         scaledSize: new window.google.maps.Size(30, 30),
                                //     }
                                // }
                                var iconX = undefined
                                let bt = undefined
                                // if (filterList && filterActive)
                                bt = filterList.find((item) => item.key === parseInt(marker.btid))

                                if (marker.is_xml === '1') {
                                    iconX = {
                                        url: require('../../assets/fnac.png'),
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: new window.google.maps.Point(1, 1),
                                        scaledSize: new window.google.maps.Size(25, 25),
                                    }
                                } else {
                                    let st = bt?.icon ? bt.icon : 'j'
                                    // console.log("icone name : " , st)
                                    iconX = {
                                        url: require('../../assets/icons/' + st + '.png'),
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: new window.google.maps.Point(1, 1),
                                        scaledSize: new window.google.maps.Size(25, 25),
                                    }
                                }


                                if ((filterActive && bt.raised === true) || (!filterActive)) {
                                    let distance = undefined
                                    if (userPos?.lat && userPos?.lng)
                                        distance = readableDistance(userPos.lat, userPos.lng, marker.lat, marker.lng)

                                    if (distance)
                                        marker["distance"] = distance

                                    return (
                                        <Marker
                                            //key={`${marker.lat}-${marker.lng}-${marker.ev_name}-${marker.ev_id}`}
                                            key={index}
                                            position={{
                                                lat: parseFloat(marker.lat) + (Math.random() * (0.00001 - 0.00005))
                                                , lng: parseFloat(marker.lng) //- (Math.random() * (0.000001 - 0.000008))
                                            }}
                                            onClick={() => {
                                                setSelected(marker)
                                            }}
                                            icon={iconX}
                                            clusterer={clusterer}
                                        />
                                    )
                                }

                            })}
                    </MarkerClusterer>
                    :
                    // whithout cluster
                    markers.map((marker, index) => {
                        var iconX = undefined
                        let bt = undefined
                        // if (filterList && filterActive)
                        bt = filterList.find((item) => item.key === parseInt(marker.btid))

                        if (marker.is_xml === '1') {
                            iconX = {
                                url: require('../../assets/fnac.png'),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(0, 0),
                                scaledSize: new window.google.maps.Size(25, 25),
                            }
                        } else {
                            let st = bt?.icon ? bt.icon : 'j'
                            iconX = {
                                url: require('../../assets/icons/' + st + '.png'),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(1, 1),
                                scaledSize: new window.google.maps.Size(25, 25),
                            }
                        }


                        if ((filterActive && bt.raised === true) || (!filterActive)) {
                            let distance = undefined
                            if (userPos?.lat && userPos?.lng)
                                distance = readableDistance(userPos.lat, userPos.lng, marker.lat, marker.lng)

                            if (distance)
                                marker["distance"] = distance

                            return (
                                <Marker
                                    //key={`${marker.lat}-${marker.lng}-${marker.ev_name}-${marker.ev_id}`}
                                    key={index}
                                    position={{
                                        lat: parseFloat(marker.lat) + (Math.random() * (0.00001 - 0.00008))
                                        , lng: parseFloat(marker.lng) //- (Math.random() * (0.00001 - 0.00008))
                                    }}
                                    onClick={() => {
                                        setSelected(marker)
                                    }}
                                    icon={iconX}
                                >
                                </Marker>
                            )
                        } else return null

                    })
                }
                {userPos
                    ?
                    <Marker
                        key="iamhere-1"
                        position={{lat: parseFloat(userPos.lat), lng: parseFloat(userPos.lng)}}
                        icon={iconIAmHere}
                        zIndex={999}
                    />
                    : null}


                {selected ? (
                    <InfoWindow
                        position={{lat: parseFloat(selected.lat), lng: parseFloat(selected.lng)}}
                        onCloseClick={() => {
                            setSelected(null)
                        }}
                        options={{maxWidth: 1200}}
                    >

                        <div className="maps-card"
                             style={{backgroundImage: `url(${bgImage})`, backgroundSize: "contain"}}>
                            <div style={{backgroundColor: "rgba(25,30,40,0.75)", height: "100%"}}>

                                <Row>
                                    <Col xs={12}>
                                        <p className="map-card-title maps-card-title">#
                                            {
                                                (selected.ev_name.length > 28
                                                        ?
                                                        selected.ev_name.slice(0, 28) + '...'
                                                        :
                                                        selected.ev_name
                                                )}
                                        </p>
                                        {/************************ **** ************************/}
                                        {/************************ IMAGE ***********************/}
                                        {/************************ **** ************************/}
                                        <Image
                                            className="maps-card-image"
                                            src={` ${
                                                selected.is_xml === '1' ?
                                                    selected.imgPathLarge :
                                                    selected.image_name ?
                                                        config.BASE_URL + "img/events/" + selected.image_name + "?" +
                                                        selected.updated_at
                                                        : config.BASE_URL + "img/events/cover-" + selected.ev_id + ".jpg" + selected.updated_at}`}
                                        />

                                        {/************************ **** ************************/}
                                        {/************************ DATE ************************/}
                                        {/************************ **** ************************/}
                                        <p className="maps-card-subtitle"> {formatDate(selected.is_xml,
                                            selected.dt_from, selected.dt_to)
                                        }
                                        </p>

                                        {/************************ **** ************************/}
                                        {/********************** DISTANCE **********************/}
                                        {/************************ **** ************************/}
                                        <Zoom className="distance-top-container">
                                            <p className="maps-card-subtitle distance-top"> {selected.distance}
                                            </p>
                                        </Zoom>
                                        {/************************ **** ************************/}
                                        {/*********************** TARIF ************************/}
                                        {/************************ **** ************************/}
                                        <p className="maps-card-tarif "> {
                                            readableTarif(selected?.isFree, selected.tarif, selected.tarif_promo)
                                        }
                                        </p>

                                        {/************************ **** ************************/}
                                        {/************************ TEXT ************************/}
                                        {/************************ **** ************************/}
                                        <div className="maps-card-text">
                                            {parse(selected.details.replaceAll("<br>", ""), {trim: true})}
                                            {/*<Button*/}
                                            {/*    onClick={() => setOpen(!open)}*/}
                                            {/*    aria-controls="fade-text"*/}
                                            {/*    aria-expanded={open}*/}
                                            {/*    className="card-button-expand"*/}
                                            {/*>*/}
                                            {/*    ( ... )*/}
                                            {/*</Button>*/}
                                            {/*<Fade in={open}>*/}
                                            {/*         <span id="fade-text">*/}
                                            {/*             {open ?*/}
                                            {/*                 parse( selected.details.slice(251))*/}
                                            {/*                 : null*/}
                                            {/*             }*/}
                                            {/*         </span>*/}
                                            {/*</Fade>*/}
                                        </div>
                                    </Col>
                                </Row>
                                {(selected.is_xml == 1 || selected.is_xml === undefined) ?
                                    <Container className="align-content-middle">
                                        <Row className="align-content-middle">
                                            <Col xs={6} style={{textAlign: "center"}}>
                                                <Button href={selected.deeplink}
                                                        target="_blank"
                                                        className="card-btn-details-partner"
                                                        rel="noopener noreferrer"
                                                        block
                                                >
                                                    Réserver maintenant !
                                                </Button>
                                            </Col>
                                            <Col xs={6} style={{textAlign: "center"}}>
                                                <Button
                                                    className="card-btn-details-partner"
                                                    block
                                                    as={Link}
                                                    to={`/details/${selected.ev_id}/${selected.is_xml}`}
                                                >
                                                    Détails
                                                </Button>

                                            </Col>
                                        </Row>
                                    </Container>
                                    :
                                    <Row>
                                        <Col className="d-flex justify-content-end" xs>
                                            <Button
                                                className="card-btn-details"
                                                as={Link}
                                                target="_blank"
                                                to={`/details/${selected.ev_id}/${selected.is_xml}`}
                                            >
                                                Détails
                                            </Button>

                                        </Col>
                                    </Row>
                                }
                            </div>

                        </div>
                    </InfoWindow>

                    // <InfoWindow
                    //
                    //     position={{lat: parseFloat(selected.lat), lng: parseFloat(selected.lng)}}
                    //     onCloseClick={() => {
                    //         setSelected(null);
                    //     }}
                    // >
                    //     <Card className="maps-card">
                    //         <Card.Body>
                    //             <Card.Title className="map-card-title maps-card-title">#
                    //                 {
                    //                     (selected.ev_name.length > 28
                    //                             ?
                    //                             selected.ev_name.slice(0, 28) + '...'
                    //                             :
                    //                             selected.ev_name
                    //                     )}
                    //             </Card.Title>
                    //             <Card.Subtitle
                    //                 className="mb-2 maps-card-subtitle">{moment(selected.dt_to).calendar()}</Card.Subtitle>
                    //             <Card.Text className="maps-card-text">
                    //                 {/*{selected.details}*/}
                    //                 {selected.details.slice(0, 250) + " "}
                    //                 <Button
                    //                     onClick={() => setOpen(!open)}
                    //                     aria-controls="fade-text"
                    //                     aria-expanded={open}
                    //                     className="card-button-expand"
                    //                 >
                    //                     ( ... )
                    //                 </Button>
                    //                 <Fade in={open}>
                    //                     <span id="fade-text">
                    //                         {open ?
                    //                             selected.details.slice(251)
                    //                             : null
                    //                         }
                    //                     </span>
                    //                 </Fade>
                    //             </Card.Text>
                    //
                    //
                    //         </Card.Body>
                    //         <Image
                    //             className="maps-card-image"
                    //             src={` ${selected.is_xml === '1' ?
                    //                 selected.imgPathLarge :
                    //                 config.BASE_URL + "img/events/cover-" + selected.ev_id + ".jpg"}`}
                    //             roundedCircle/>
                    //         <Row>
                    //             <Col xs={12} sm={6}>
                    //                 <Button
                    //                     className="card-book"
                    //                     as={Link}
                    //                     to={`/details/${selected.ev_id}/${selected.is_xml}`}
                    //                     block
                    //                 >
                    //                     Détails
                    //                 </Button>
                    //             </Col>
                    //
                    //             {(selected.is_xml == 1 || selected.is_xml === undefined)
                    //                 ? (
                    //                     <Col item xs={12} sm={6}>
                    //                         <Button href={selected.deeplink}
                    //                                 target="_blank"
                    //                                 className="card-book"
                    //                                 rel="noopener noreferrer"
                    //                                 block
                    //
                    //                         >
                    //                             Réserver maintenant !
                    //                         </Button>
                    //                     </Col>
                    //                 ) : null
                    //             }
                    //         </Row>
                    //     </Card>
                    // </InfoWindow>
                ) : null}

            </GoogleMap>
            {/*</LoadScript>*/}
        </div>
    ) : <></>

}


function Locate({panTo}) {
    return (
        <button
            className="locate"
            onClick={() => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        panTo({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    },
                    () => null
                );
            }}
        >
            <img src={require('../../assets/compass.svg')} alt="compass"/>
        </button>
    )
}

