import React from "react"
import { css } from "@emotion/react"
import CircleLoader from "react-spinners/CircleLoader"

//Style
import "./spinner.css"


const override = css`
  display: block;
  margin: 25 auto;
  border-color: gray;
`;

export default class Spinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isLoading !== this.props.isLoading) {
            this.updateState()
        }

    }

    updateState = () => {
        this.setState({ loading: this.props.isLoading })
    }


    render() {
        const { loading } = this.state
        return (
            <div className="spinner">
                <p style={{color: '#ffffff'}}>{loading ? this.props.textToDisplay : ""}</p>
                <CircleLoader
                    css={override}
                    size={200}
                    color={"#888585"}
                    loading={loading}
                />
            </div>
        );
    }
}
