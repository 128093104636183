import React, {Component} from 'react'
import {Link} from 'react-router-dom'

// Private Component
import Spinner from './Spinner'
import {confirmEUEmail} from "../../actions/authActions"

//Style
import "./confirm.css"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

export default class ConfirmEU extends Component {

    // A bit of state to give the user feedback while their email
    // address is being confirmed on the User model on the server.
    state = {
        confirming: true
    }

    /**
     *
     */
    componentDidMount = () => {
        const {cd, em} = this.props.match.params
        if (!!cd && !!em) {
            confirmEUEmail(cd, em)
                .then(response => {
                    if (response.status === 200 && response.data === "ok") {
                        this.setState({ confirming: false })
                    }
                })
                .catch(err => console.log(err))
        }
    }

    render = () =>
        <Container maxWidth="sm">
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '95vh'}}
            >
                {this.state.confirming
                    ?
                    <p className="confirm-intro">Vérification de vos données...</p>
                    :
                    <p className="confirm-intro">Votre email est vérifié,
                        retournez sur votre application justNow pour vous connecter avec vos identifiants</p>
                }
                <div className='confirm'>
                    {this.state.confirming
                        ?
                        <Spinner size='8x' spinning={'spinning'}/>
                        : null
                    //     <Button
                    //     variant="contained"
                    //     color="primary"
                    //     type="submit"
                    //     component={Link}
                    //     onClick={
                    //         //window.location.replace("intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end")
                    //         window.location.replace("intent://ekrak.com/#Intent;scheme=https;package=com.ekrak;end")
                    //     }
                    //     //to={'/login'}
                    //
                    //         >
                    //     Ouvrir l'application
                    // </Button>
                    }
                </div>
            </Grid>
        </Container>
}
