import React, {Fragment, useEffect, useState} from 'react'
import {StyleSheet, PDFViewer, PDFDownloadLink} from '@react-pdf/renderer'
import {Link, useParams} from "react-router-dom"
import {
    BrowserView,
} from "react-device-detect"

import Invoice from "./Invoice"
import {getBillingDetail} from "../../../api/billingActions"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col";


// Create styles
const styles = StyleSheet.create({
    noDataContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        textAlign: 'center'
    },
    noDataText: {
        color: '#ffffff',

    },
    page: {
        flex: 1,
        flexDirection: 'column',
        orientation: 'portrait',
        width: 400,
        backgroundColor: '#E4E4E4',
        borderTop: 1,
        borderColor: '#7c7f84',

    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    image: {
        width: '40%',
        padding: 10,
        zIndex: 9
    },
    centerImage: {
        width: 400,
        height: 150,
        alignItems: "center",
    },
    subLogoText: {
        fontSize: 10
    },
    titleInvoice: {
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 50
    },
    detailContainer: {
        flexDirection: 'row',
        border: 2,
        fontSize: 10,
        paddingRight: 20,
        paddingLeft: 20
    },
    inlinePrice: {
        alignContent: 'right',
        textAlign: 'right',
        marginRight: 80
    }
})

// Create Document Component
const Billing = () => {
    // Récupération des parametres de l'url
    const {uuid} = useParams()
    const [invoice, setInvoice] = useState(undefined)

    useEffect(() => {
        console.log(uuid)
        getBillingDetail(uuid)
            .then(response => {
                console.log(response.data)
                if (response.data.status === 204) {
                    setInvoice(undefined)
                } else {
                    setInvoice(response.data)
                    //console.table(response.data)
                }
            })
            .catch(error => {
                console.log("catch error")
            })
    }, [])

    /**
     * Note :  Return only download link due to some browser error on mobile (on chrome)
     */
    return (
        <div>
            {invoice ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '90vh',
                }}>
                    <PDFDownloadLink
                        style={{
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                        document={<Invoice invoice={invoice}/>}
                        fileName="facturation justnow.pdf">
                        {({blob, url, loading, error}) =>
                            loading ? 'Chargement des données de facturation...' :
                                <Button
                                    className="card-book"
                                >
                                    Télécharger ma facture
                                </Button>
                        }
                    </PDFDownloadLink>
                    <BrowserView>
                        <PDFViewer width="100%" height="710" className="app">
                            <Invoice invoice={invoice}/>
                        </PDFViewer>
                    </BrowserView>
                </div>


                :
                <div style={styles.noDataContainer}>
                    <text style={styles.noDataText}>Aucune donnée à afficher</text>
                </div>
            }
        </div>
    )
}

export default Billing
