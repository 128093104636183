import React from 'react'
import Detail from "./Detail"

function DetailIndex() {
    return (
        <div>
            <Detail/>
        </div>
    )
}

export default DetailIndex
