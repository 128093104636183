import React, {useCallback, useEffect, useState} from "react"
/*global google*/

// Component
import {
    GoogleMap, useLoadScript,
    Marker,
} from "@react-google-maps/api"
import {DirectionsRenderer} from "@react-google-maps/api"


// Style
import mapStyles from "./mapStyles";
import {log} from "util";

// Constants
const libraries = ["places", "directions"];
const mapContainerStyle = {
    height: "20vh",

};
const options = {
    styles: mapStyles,
    // disableDefaultUI: true,
    zoomControl: true,
}

const center = {
    lat: 43.77,
    lng: 7.505690,
}


/**
 *
 * @param props
 * @returns {string|*}
 * @constructor
 */
export default function MiniMap(props) {
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });
    const [markers, setMarkers] = useState()
    const [selected, setSelected] = useState(null)
    const [styleMap, setStyleMap] = useState(mapContainerStyle)
    const [userPos, setUserPos] = useState(undefined)

    useEffect(() => {
        setMarkers(props.Position)
        console.log("SET SHOP POSITION", props.Position.lat)
        setUserPos({
            lat: localStorage.getItem('lat'),//43.710175,//position.coords.latitude
            lng: localStorage.getItem('long'),//7.261953,//position.coords.longitude
        })

    }, [props.Position]);



    const mapRef = React.useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map
    }, [])

    const panTo = useCallback(({lat, lng}) => {
        mapRef.current.panTo({lat, lng})
        mapRef.current.setZoom(14)
    }, [])

    const onLoad = marker => {
        console.log('marker: ')
    }

    if (loadError) return "Error";
    if (!isLoaded) return "Loading..."

    const iconIAmHere = {
        url: require('../../assets/iamhere.png'),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(10, 25),
        scaledSize: new window.google.maps.Size(40, 40),
    }

    function MyDirectionsRenderer(props) {
        const [directions, setDirections] = useState(null);
        const {origin, destination, travelMode} = props;

        useEffect(() => {
            const directionsService = new google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: new google.maps.LatLng(origin.lat, origin.lng),
                    destination: new google.maps.LatLng(destination.lat, destination.lng),
                    travelMode: travelMode
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        const overViewCoords = result.routes[0].overview_path
                        setDirections(result);
                    } else {
                        console.log(new google.maps.LatLng(origin.lat, origin.lng))
                        console.log(`error fetching directions ${result}`);
                    }
                }
            )

        }, []);

        return (
            <React.Fragment>
                {directions && <DirectionsRenderer directions={directions}/>}
            </React.Fragment>
        );
    }

    return (
        <div>
            <GoogleMap
                id="map"
                mapContainerStyle={styleMap}
                zoom={10}
                center={center}
                options={options}
                onLoad={onMapLoad}
            >

                {/*User position Marker*/}
                {userPos
                    ?
                    <Marker
                        key="iamhere-1"
                        position={{lat: parseFloat(userPos.lat), lng: parseFloat(userPos.lng)}}
                        icon={iconIAmHere}
                        zIndex={999}
                    />
                    : null}


                {markers && userPos ? (
                        <MyDirectionsRenderer
                            origin={{lat: parseFloat(userPos.lat), lng: parseFloat(userPos.lng)}}
                            destination={{lat: parseFloat(markers.lat), lng: parseFloat(markers.lng)}}
                            travelMode={google.maps.TravelMode.DRIVING}
                        />)
                    : null}

                {/*{markers && userPos ?*/}
                {/*<MyDirectionsRenderer*/}
                {/*    origin={{lat: 41.8507300, lng:-87.6512600}}*/}
                {/*    destination={{lat: 41.8525800, lng: -87.6514100}}*/}
                {/*    travelMode={google.maps.TravelMode.DRIVING}*/}
                {/*/>*/}
                {/*    : null*/}
                {/*}*/}

            </GoogleMap>
        </div>
    )
}
