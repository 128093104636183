import axios from "axios";
import moment from "moment";

// Private Component
import {parseBool} from "../utils/functions";
import config from "../config"

const BASE_URL = config.BASE_URL
const apiX = axios.create({
    baseURL: BASE_URL
})

//TODO function to check connexion status and get JWT token instead of cid
const CID = localStorage.getItem('cid')
const UID = localStorage.getItem('uid')

export const retrieveUserData = async (_cid) => {
    return await apiX
        .get("api/users/retrieve/" + _cid)
        .catch(err =>
            console.log("lose " + err)
        );
}




export const updateUserCompany = async (_companyName
                                        , _firstName
                                        , _lastName
                                        , _email
                                        , _phone
                                        , _password = ''
                                        , _description
                                        , _address
                                        , _city
                                        , _zipcode
                                        , _country
                                        , _latitude
                                        , _longitude
                                        , _cid = CID
                                        , _uid = UID
                                                            ) => {

    const dataUser = {
        id: parseInt(_cid),
        firstname: _firstName,
        lastname: _lastName,
        email: _email,
        password: _password
    }
    const dataCompany = {
        id: parseInt(_cid),
        name: _companyName,
        detail: _description,
        address: _address,
        city: _city,
        cp: _zipcode,
        country: _country,
        latitude: _latitude,
        longitude: _longitude,
        phone: _phone
    }

    // console.log(dataCompany)

    const updateUser = axios.post(BASE_URL+`api/users/updateuser`, dataUser, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const updateCompany = axios.post(BASE_URL+`api/users/updatecompany`, dataCompany, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return axios.all([updateUser, updateCompany])
        // .then(axios.spread((...response) => {
        //     console.log("multi spread axios")
        // }))
        .catch(errors => {
            console.log(errors)
        })

}


export const newPasswordRequest = async (_email) => {
    const param = {
        email: _email,
    }
    return await apiX
        .post("api/email/sendnewpropassword", param)

}

/**
 * Note : Delete user, company and billings from system
 * @param _code
 * @param _email
 * @param _cid
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const deleteUserAndCompanyWithCode =  async (_code, _email, _cid) => {
    const param = {
        code: _code,
        email: _email,
        cid: _cid,
    }

    return await apiX
        .post("/api/users/deleteallpro", param)
}


export const deleteEUAccount = async (_euid) => {
    let data = new FormData()
    data.append('euid', _euid)

    return await apiX
        .post("api/endusers/delete", data,
            config
        )
}


export const deleteEUAccountWithEmail = async (_code, _email) => {
    const param = {
        code: _code,
        email: _email,
    }
    return await apiX
        .post("api/endusers/deletebyemail", param)
}

export const sendConfirmationForDeleteAccount = async (_email) => {
    return await apiX
        .get("api/email/sendconfirmationfordeleteaccount/" + _email)
}
