// Component
import axios from "axios"
import moment from "moment"

// Private Component
import config from "../config"

const BASE_URL =  config.BASE_URL
const apiX = axios.create({
    baseURL: BASE_URL
})


export const getBillingDetail = async (_uuid) => {
    return await apiX
        .get("api/billing/one/" + _uuid)
}
