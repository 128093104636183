import  React, {Component} from 'react'
import IconButton from '@material-ui/core/IconButton'

const styles = {
    container: {
        position: 'absolute',
        top: 100,
        right: 15,
        zIndex:999,
        width: 30,
        height:120,
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    spButton: {
        width: 28,
        color: '#ffffff',
    }
}

export default class Tools extends Component{
    render() {
        const { reduceMySize, maximizeMySize, openPanel } = this.props
        return(
            <div style={styles.container}>
                <IconButton style={styles.spButton} onClick={maximizeMySize}>+</IconButton>
                <IconButton style={styles.spButton} onClick={reduceMySize}>-</IconButton>
                <IconButton style={styles.spButton} onClick={openPanel}>
                    <i
                    className={["fa fa-cog fa-xs" ] }>
                </i> </IconButton>
            </div>
        )
    }
}
