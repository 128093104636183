import React from "react"
import { css } from "@emotion/react"
import ClockLoader from "react-spinners/ClockLoader"


const override = css`
  display: block;
  margin: 25 auto;
  border-color: gray;
`;

export default class Spinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {
        return (
            <div className="sweet-loading">
                <ClockLoader
                    css={override}
                    size={200}
                    color={"#888585"}
                    loading={this.state.loading}
                />
            </div>
        );
    }
}
