import React, {useEffect, useState} from "react"
import {Link} from 'react-router-dom'

//Component
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
//import LightSpeed from 'react-reveal/LightSpeed'
import { Zoom } from "react-awesome-reveal"


// Private Component
import {userService} from '../../_services'

// Style
import "./navbar.css"


const Justnavbar = () => {

    const [userCompanyName, setUserCompanyName] = useState(localStorage.getItem('cname'))
    const [isConnected, setIsConnected] = useState(userService.getUserConnexionStatus())

    useEffect(() => {
        !localStorage.getItem('cname') ? setUserCompanyName(undefined) : setUserCompanyName(localStorage.getItem('cname'))
        setIsConnected(userService.getUserConnexionStatus())
    })


    return (
        <Navbar bg="light" expand="lg">
            <Zoom style={{marginLeft: 5}}>
                <Navbar.Brand as={Link} to="/"><img src="https://justnow.app/logo.png"
                                                    className="logo-navbar"/></Navbar.Brand>
            </Zoom>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/about">Comment ça marche</Nav.Link>
                    <NavDropdown title="Partenaires" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/moveon">
                            <i className="fa fa-lock pr-1" aria-hidden="true"> </i> Créer un compte
                            Pro</NavDropdown.Item>
                        <NavDropdown.Item href="/about/index.php#section_contact">
                            <i className="fa fa-envelope  pr-1" aria-hidden="true"> </i> Nous
                            contacter</NavDropdown.Item>
                        <p style={{paddingLeft: 20, paddingRight: 20}}><NavDropdown.Divider/></p>
                        <NavDropdown.Item href="/about/">
                            <i className="fa fa-info-circle  pr-1" aria-hidden="true"> </i> À propos</NavDropdown.Item>
                        <NavDropdown.Item href="/legal/cgu.html">
                            <i className="fa fa-question-circle pr-1" aria-hidden="true"> </i> Mentions
                            Légales</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )


    // return(
    //     <Navbar bg="light" expand="lg">
    //         <LightSpeed left>
    //             <Navbar.Brand as={Link} to="/"><img src="https://justnow.app/logo.png" className="logo-navbar"/></Navbar.Brand>
    //         </LightSpeed>
    //         <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //         <Navbar.Collapse id="basic-navbar-nav">
    //             <Nav className="mr-auto">
    //                 <Nav.Link href="/about">Comment ça marche</Nav.Link>
    //                 { !isConnected ?
    //                     (<NavDropdown title="Partenaires" id="basic-nav-dropdown">
    //                         <NavDropdown.Item as={Link} to="/login">
    //                             <i className="fa fa-sign-in" aria-hidden="true"> </i> Me connecter</NavDropdown.Item>
    //                         <NavDropdown.Item as={Link} to="/register">
    //                             <i className="fa fa-lock" aria-hidden="true"> </i> Créer un compte Pro</NavDropdown.Item>
    //                         <NavDropdown.Item >
    //                             <i className="fa fa-envelope-o" aria-hidden="true"> </i> Nous contacter</NavDropdown.Item>
    //                         <NavDropdown.Divider />
    //                         <NavDropdown.Item as={Link} to="/about">
    //                             <i className="fa fa-info" aria-hidden="true"> </i> À propos</NavDropdown.Item>
    //                 </NavDropdown>)
    //                     :
    //                     (
    //                         <NavDropdown title={`${userCompanyName}`} id="basic-nav-dropdown">
    //                             <NavDropdown.Item as={Link} to="/dashboard">
    //                                 <i className="fa fa-tachometer" aria-hidden="true"> </i> Tableau de bord</NavDropdown.Item>
    //                             <NavDropdown.Item as={Link} to="/adminpro" >
    //                                 <i className="fa fa-plus" aria-hidden="true"> </i> Ajouter un événement</NavDropdown.Item>
    //                             <NavDropdown.Divider />
    //                             <NavDropdown.Item as={Link} to="/moncomptepro" >
    //                                 <i className="fa fa-user" aria-hidden="true"> </i> Mon compte</NavDropdown.Item>
    //                             <NavDropdown.Item as={Link} to="/premiumpro" >
    //                                 <i className="fa fa-trophy premium" aria-hidden="true"> </i> Premium</NavDropdown.Item>
    //                             <NavDropdown.Item as={Link} to="/login">
    //                                 <i className="fa fa-sign-out" aria-hidden="true"> </i> Déconnexion</NavDropdown.Item>
    //                         </NavDropdown>
    //                         )
    //                 }
    //             </Nav>
    //         </Navbar.Collapse>
    //     </Navbar>
    // )
}


export default Justnavbar
