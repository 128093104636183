// Component
import axios from "axios"
import moment from "moment"

// Private Component
import {parseBool} from "../utils/functions"
import config from "../config"

const BASE_URL = config.BASE_URL
const CID = localStorage.getItem('cid')
const apiX = axios.create({
    baseURL: BASE_URL
})
const LAT = 43.7743
const LONG = 7.49

/**
 *
 * @param _id
 * @returns {Promise<AxiosResponse<any>|void>}
 */
export const setVisit = async (_id) => {
    return await apiX
        .get("api/events/setvisit/" + _id)
        .catch(err =>
            console.log("lose " + err)
        )
}

/**
 * Récupération des détails d'un événement en fonction de son id
 * @param id
 * @returns {Promise<string|void>}
 */
export const getEventDetail = async (id) => {
    return await apiX
        .get("api/events/fullone/" + id)
        .catch(err =>
            console.log("lose " + err)
        )
}

/**
 *
 * @param id
 * @returns {Promise<AxiosResponse<any>|void>}
 */
export const getEventPartnerDetail = async (id) => {
    return await apiX
        .get("api/events/partner/fullone/" + id)
        .catch(err =>
            console.log("lose " + err)
        );
};


/**
 *
 * @param id
 * @returns {Promise<string|void>}
 */
export const removeEvents = async (id) => {
    return await apiX
        .delete("api/events/remove", {
                data: {id: parseInt(id, 10)}
            }
        )
        .then(response => "ok")
        .catch(err =>
            console.log("lose " + err)
        );
};


/**
 * Récupération des événements depuis l'API (partenaires Fnacspectacles)
 * Pagination sans retour de nombre de pages totales
 * @param page
 * @param lat
 * @param long
 * @param _demo
 * @param _mpId
 * @param _partner
 * @returns {Promise<AxiosResponse<any>|void>}
 */
export const showMixedEvents = async (page = 1, lat = localStorage.getItem('lat')
                                      , long = localStorage.getItem('long')
    , _demo = 0, _mpId = null, _partner = false
) => {
    if (_partner === true || _partner === 1) {
        _partner = 1
    } else
        _partner = 0

    if (lat && long) {
        return await apiX
            .get("api/events/mixed/" + page + '/' + lat + '/' + long + '/' + _demo + '/' + _mpId + '/' + _partner)
            .catch(err =>
                console.log("lose " + err)
            )
    } else {
        return false
    }

}


/**
 * Note :  Retrieve all event inside the boudaries
 * @param _lat
 * @param _long
 * @param _boundaries
 * @param _demo
 * @param _mpId
 * @param _partner
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSmartMoveEvent = async (_lat = LAT.toString(), _long = LONG.toString(),
                                        _boundaries, _demo = 0, _mpId = null, _partner = 0) => {
    if (_partner === true || _partner === 1) {
        _partner = 1
    } else
        _partner = 0
    return await apiX
        .get("api/events/smartmove/" + _lat + '/' + _long + '/' + _boundaries + '/' + _demo + '/'
            + _mpId + '/' + _partner)
}


/**
 *
 * @param lat
 * @param long
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSmartPerimeter = async (lat = LAT.toString(), long = LONG.toString()) => {
    return await apiX
        .get("api/events/smartperimeter/" + lat + '/' + long)
}

/**
 * Récupération des événements depuis l'API
 * Pagination sans retour de nombre de pages totales
 * @param page
 * @returns {Promise<AxiosResponse<any>|void>}
 */
export const showEvents = async (page = 1) => {
    return await apiX
        .get("api/events/" + page)
        .catch(err =>
            console.log("lose " + err)
        );
};

/**
 * Récupération des événements depuis l'API (partenaires Fnacspectacles)
 * Pagination sans retour de nombre de pages totales
 * @param page
 * @returns {Promise<AxiosResponse<any>|void>}
 */
export const showEventsPartners = async (page = 1) => {
    return await apiX
        .get("api/events/partner/" + page)
        .catch(err =>
            console.log("lose " + err)
        );
};


/**
 *
 * @param cid
 * @returns {Promise<AxiosResponse<any>|void>}
 */
export const showOnlyMyEvents = async (_cid) => {
    const param = {
        cid: _cid,
    };
    return await apiX
        .post("api/events/mines", param)
        .catch(err =>
            console.log("lose " + err)
        );
};

/**
 * Insertion d'un nouvel événement
 * @param blobUrl
 * @param _eventname
 * @param _type
 * @param _desc
 * @param _tarif
 * @param _tarifPromo
 * @param _free
 * @param _dt1
 * @param _dt2
 * @returns {Promise<void>}
 */
export const uploadEvent = async (blobUrl, _eventname, _type, _desc, _tarif, _tarifPromo, _free, _dt1, _dt2) => {
    // Conversion from blob url to blob
    let blob = await fetch(blobUrl).then(r => r.blob());
    const promise = new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => {
            if (!!reader.result) {
                resolve(reader.result)
            } else {
                reject(Error("Failed converting to base64"))
            }
        }
    })

    // Sending data
    return await promise.then(result => {
        let data = new FormData();
        data.append('image', blob)
        data.append('_eventname', _eventname)
        data.append('_type', _type)
        data.append('_desc', _desc)
        data.append('_tarif', (_tarif === undefined || _tarif === '') ? 0 : _tarif)
        data.append('_tarifPromo', (_tarifPromo === undefined || _tarifPromo === '') ? 0 : _tarifPromo)
        data.append('_free', _free)
        data.append('_dt1', moment(_dt1).format("YYYY-MM-DD HH:mm:00"))
        data.append('_dt2', moment(_dt2).format("YYYY-MM-DD HH:mm:00"))
        data.append('_cid', CID)
        return data = apiX
            .post(`api/events/new`, data)
        // .then(response => {
        //     return response
        // })
        // .catch(error =>  {
        //     console.log(error)
        //     return error
        // })

    }, err => {
        console.log(err)
    })
}


export const updateEvent = async (blobUrl, _eventname, _type, _desc, _tarif
    , _tarifPromo, _free, _dt1, _dt2, _src, _id) => {
    // let reader = new FileReader();
    // let base64data = ''
    // reader.readAsDataURL(blob);
    // reader.onloadend = () => {
    //     let base64data = reader.result;
    //     console.log(base64data);
    // }

    // Conversion from blob url to blob
    if (blobUrl !== _src) {
        console.log("image has changed")
        let blob = await fetch(blobUrl).then(r => r.blob())
        const promise = new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onload = () => {
                if (!!reader.result) {
                    resolve(reader.result)
                } else {
                    reject(Error("Failed converting to base64"))
                }
            }
        })
        // Sending data
        await promise.then(result => {
            let data = new FormData();
            data.append('id', _id)
            data.append('image', blob)

            const dataToUpload = {
                id: parseInt(_id),
                name: _eventname,
                typeId: parseInt(_type),
                details: _desc,
                tarif: (_tarif === undefined || _tarif === '') ? 0 : parseFloat(_tarif),
                tarif_promo: (_tarifPromo === undefined || _tarifPromo === '') ? 0 : parseFloat(_tarifPromo),
                isFree: parseBool(_free),
                dtFrom: moment(_dt1).format("YYYY-MM-DD HH:mm:00"),
                dtTo: moment(_dt2).format("YYYY-MM-DD HH:mm:00"),
                image: blob
            }

            const updateData = axios.post(BASE_URL + `api/events/update`, dataToUpload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const updatePic = axios.post(BASE_URL + `api/events/updatepic`, data)

            return axios.all([updateData, updatePic])
                .then(axios.spread((...response) => {
                    console.log("multi spread axios")
                }))
                .catch(errors => {
                    console.log(errors)
                })
        }, err => {
            console.log(err)
        })

        // await promiseBlob.then(result => {
        //     return apiX
        //     .post(`api/events/update`, dataToUpload, {
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     })
        // })


    } else {
        const dataToUpload = {
            id: parseInt(_id),
            name: _eventname,
            typeId: parseInt(_type),
            details: _desc,
            tarif: (_tarif === undefined || _tarif === '') ? 0 : parseFloat(_tarif),
            tarif_promo: (_tarifPromo === undefined || _tarifPromo === '') ? 0 : parseFloat(_tarifPromo),
            isFree: parseBool(_free),
            dtFrom: moment(_dt1).format("YYYY-MM-DD HH:mm:00"),
            dtTo: moment(_dt2).format("YYYY-MM-DD HH:mm:00"),
        }

        return await apiX
            .post(`api/events/update`, dataToUpload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
    }
}

export const reportAnEvent = async (eid) => {
    const param = {
        eid: eid,
    };
    return await apiX
        .post("api/complaints/add", param)
        .catch(err =>
            console.log("lose " + err)
        );
};

/**
 *
 * @returns {Promise<AxiosResponse<any>|void>}
 * @param _token
 * @param _topic
 */
export const registerFCMTokenToTopic = async (_token, _topic = "allweb") => {
    return await apiX
        .get("api/events/registerwebtokentotopic/" + _token + '/' + _topic)
        .catch(err =>
            console.log("lose " + err)
        );
};
