/* Default */
import React, {Component} from 'react'
import {Router, Switch, Route} from 'react-router-dom'
import CookieConsent from "react-cookie-consent"

/* Style */
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

/* API */
import {getSmartMoveEvent, getSmartPerimeter, showMixedEvents} from "./api/eventActions"

/* Component private (home made) */
// import {PrivateRoute} from "./_routes/PrivateRoute"
import Justnavbar from "./components/navbar/navbar_component"
import BigMaps from "./components/maps/maps"
import EventScroller from "./components/eventScroller/eventScroller"
// import { Login } from "./components/auth/login/login"
// import Register from "./components/auth/registerx"
// import IndexPro from "./components/proUserAdmin"
// import AccountPro from "./components/proUserAdmin/accountPro"
// import ModalUpdater from "./components/proUserAdmin/modalUpdater (Deprecated)/ModalUpdater"
// import { Dashboard } from "./components/dashboard/Dashboard"
// import Premium from "./components/proUserAdmin/premium"
import FloatingButton from "../src/components/floatingButton/FloatingButton"
import DetailIndex from "./components/detail"
import ForgottenEmail from "./components/fogottenEmail/ForgottenEmail"
import config from "./config"
import Confirm from "./components/confirm/Confirm"
import ConfirmEU from "./components/confirmEU/ConfirmEU"
import MoveOn from "./components/moveon/MoveOn"
import BillingIndex from "./components/proUserAdmin/billing/index"
import DeleteMe from "./components/deleteMe"
import DeleteMeConfirmation from "./components/deleteMe/deleteMeConfirmation"
import "./firebase"
import {getTheToken} from "./firebase"

// REDUX
import {batch, connect} from 'react-redux'
import {history} from './_helpers'
import {alertActions} from "./_actions"
import {userService} from "./_services"
//import {AlertStore} from "./components/alertStore/AlertStore"
import {getBusinessType} from "./api/companyActions"

let ind=0

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
              filter : undefined
            , zoom: 12
            , events: undefined
            , eventMarkers: []
            //, isConnected: userService.getUserConnexionStatus()
            , isConnected: true
            , alert: undefined
            , userPos: undefined
            , filterList : undefined
            , filterActive: false
            , isTokenFound: false
            , loadedTimes: 0
            , partners: localStorage.getItem('showPartner') ? localStorage.getItem('showPartner') === "true" ? 1 : 0 : 0
        }


        const {dispatch} = this.props
        history.listen((location, action) => {
            // clear alert on location change
            batch(() => {
                dispatch(alertActions.clear())
            })
        })
    }


    async componentDidMount() {
        this.setState({loadedTimes: this.state.loadedTimes + 1})
        // Notification stuff
        getBusinessType()
            .then(response => {
                if (typeof response.data !== 'undefined') {
                    if (response.status >= 200 && response.status <= 299) {
                        let st = response.data.map(({id, name, icon}) => ({
                                key: id,
                                label: name,
                                icon: icon,
                                raised: false
                            })
                        )
                        this.setState({filterList: st})
                    }
                }
            })
            .catch(e => console.log(e))

        await navigator.geolocation.getCurrentPosition(
            (position) => {
                // Remember local user position
                localStorage.setItem('lat', position.coords.latitude)
                localStorage.setItem('long', position.coords.longitude)
                this.setState({
                    userPos: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                })
                showMixedEvents(1, position.coords.latitude, position.coords.longitude, 0, null, this.state.partners)
                    .then(response => {
                        getSmartPerimeter(position.coords.latitude, position.coords.longitude)
                            .then((response => {
                                if (!isNaN(response.data))
                                    this.setState({zoom: this.smartZoom(response.data)})
                            }))
                            .catch(e => {
                                    this.setState({zoom: 12})
                                }
                            )
                        // let mainEvents = [...[this.state.events], ...response.data]
                        this.setState({events: response.data})
                    })
            },
            () => null
        )
    }

    /**
     * Note : apply a specific zoom in base of the research perimeter (with smart perimeter function)
     * _x as working perimeter
     */
    smartZoom(_x) {
        switch (true) {
            case (_x < 100):
                return 18
            case (_x <= 200):
                return 17
            case (_x <= 500):
                return 16
            case (_x <= 1000):
                return 15
            case (_x <= 2000):
                return 14
            case (_x <= 4000):
                return 14
            case (_x <= 8000):
                return 12
            case (_x <= 16000):
                return 12
            case (_x <= 32000):
                return 12
            case (_x <= 64000):
                return 10
            case (_x <= 128000):
                return 10
            default:
                return 12
        }
    }

    /**
     * Note : Retrieve additional event when map is moved
     */
    getEvent = async (_boundaries, _region, _reset = false) => {
        let demo = 0//await shouldISawDemoEvent()
        let mpId = null//await getMultiProId()
        // let zoom = Math.round(Math.log(360 / _region.longitudeDelta) / Math.LN2)
        // this.setState({moveZoom: zoom})
        let lat = _region.lat()
        let long = _region.lng()
        getSmartMoveEvent(lat, long, _boundaries, demo, mpId, this.state.partners)
            .then(response => {
                let stEvents = response.data
                let ids = new Set(this.state.events.map(d => d.ev_id))
                if (_reset)
                    this.setState({events: stEvents})
                else {
                    let merged = [...this.state.events, ...stEvents.filter(d => !ids.has(d.ev_id))]
                    this.setState({events: merged})
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    /**
     *
     * @param _filter
     */
    handleChangeFilter = (_filter)  => {
        if (_filter)
            this.setState({filterList: _filter})
    }

    /**
     *
     * @param _status
     */
    handleActiveFilter = (_status) => {
        if (_status)
            this.setState({filterActive: _status})
    }

    render() {
        if (this.state.events && this.state.zoom ) {
            return (
                <Router history={history}>
                    <CookieConsent
                        enableDeclineButton={true}
                        location="bottom"
                        buttonText="J'accepte"
                        declineButtonText="Je décline"
                        cookieName="justnowCook"
                        style={{
                            background: "rgba(26,28,29,0.65)"
                        }}
                        buttonStyle={{color: "#ffffff", fontSize: "13px", background: '#c03e0e', borderRadius: 6}}
                        declineButtonStyle={{
                            color: "#ffffff",
                            fontSize: "13px",
                            background: '#776c67',
                            borderRadius: 6
                        }}
                        expires={150}
                    >
                        <p>JustNow.app utilise des cookies pour nous permettre de mieux comprendre comment le site est
                            utilisé.
                            En continuant à utiliser ce site, vous acceptez cette politique.</p>
                        <p style={{fontSize: "10px"}}><a href={"https://justnow.app/legal/cookies.html"}>En savoir
                            plus</a>
                        </p>
                    </CookieConsent>
                    <Justnavbar/>
                    <Switch>
                        <Route exact path='/' render={() =>
                            <div>
                                <BigMaps eventMarkers={this.state.events}
                                         move={(_boundaries, _region, _reset) => {
                                             this.getEvent(_boundaries, _region, _reset)
                                         }}
                                         zoom={this.state.zoom}
                                         filterOp={(filter)=>this.handleChangeFilter(filter)}
                                         filterList={this.state.filterList}
                                         filterStatus={this.state.filterActive}
                                         filterStatusOp={(_status) => this.handleActiveFilter(_status)}
                                         partners={this.state.partners}
                                         partnersOp={(partners) => this.setState({partners: partners})}
                                />
                                <EventScroller initialEventsList={this.state.events}
                                               event={this.state.events}
                                               coords={this.state.userPos}
                                               filterList={this.state.filterList}
                                               filterStatus={this.state.filterActive}
                                               filterStatusOp={(_status) => this.handleActiveFilter(_status)}
                                />
                            </div>
                        }
                        />
                        {/*<PrivateRoute exact path="/dashboard" component={Dashboard} />*/}
                        {/*<PrivateRoute exact path="/updateevent" component={ModalUpdater} />*/}
                        {/*<PrivateRoute  path="/moncomptepro" component={AccountPro} />*/}
                        {/*<PrivateRoute exact path="/premiumpro" component={Premium} />*/}
                        {/*<PrivateRoute exact path='/adminpro' component={IndexPro}/>*/}
                        <Route path="/details/:eid/:xml?" component={DetailIndex}/>
                        {/*<Route exact path='/login' component={Login}/>*/}
                        <Route exact path='/about' component={() => {
                            window.location.href = config.OUTSIDE_URL + 'about/index.php'
                            return null
                        }}/>
                        <Route exact path='/moveon' component={MoveOn}/>
                        <Route exact path='/deleteme' component={DeleteMe}/>
                        <Route exact path='/deletemeconfirmation/:code/:email/:cid' component={DeleteMeConfirmation}/>
                        {/*<Route exact path='/deletemeeu' component={DeleteMeEu}/>*/}
                        {/*<Route exact path='/register' component={Register}/>*/}
                        <Route exact path='/confirm/:cd/:em' component={Confirm}/>
                        <Route exact path='/euconfirm/:cd/:em' component={ConfirmEU}/>
                        <Route exact path='/forgottenemail/:em?' component={ForgottenEmail}/>
                        <Route exact path='/billing/:uuid?' component={BillingIndex}/>
                        <Route exact path='/contact' component={() => {
                            window.location.href = config.OUTSIDE_URL + 'about/index.php#section_contact'
                            return null
                        }}/>
                    </Switch>
                    {/*<AlertStore/>*/}
                    <FloatingButton />
                </Router>
            )
        } else return null
    }
}

function mapStateToProps(state) {
    const {alert} = state
    return {
        alert
    }
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};
