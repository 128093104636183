import React, {useEffect, useState} from "react"
import Grid from "@material-ui/core/Grid"
import Spinner from "../../confirm/Spinner"
import Container from "@material-ui/core/Container"
import {deleteEUAccountWithEmail, deleteUserAndCompanyWithCode} from "../../../api/userActions"

export const DeleteMeConfirmation = (props) => {
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(undefined)
    const { code, email, cid } = props.match.params

    useEffect(() => {
        setLoading(true)
        if (code || email || cid) {
            if (cid === "none") {
                deleteEUAccountWithEmail(code, email)
                    .then(response => {
                        console.log(response)
                        setSuccess(true)
                        setLoading(false)
                    })
                    .catch(err => {
                        console.log(err)
                        setSuccess(false)
                        setLoading(false)
                    })
            } else {
                deleteUserAndCompanyWithCode(code, email, cid)
                    .then(response => {
                        console.log(response)
                        setSuccess(true)
                        setLoading(false)
                    })
                    .catch(err => {
                        console.log(err)
                        setSuccess(false)
                        setLoading(false)
                    })
            }
        }
        setTimeout(() => {
            setLoading(false)
        }, 4000)
    }, [cid])

    return (
        <Container>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '95vh'}}
            >
                {loading
                    ?
                    <p className="confirm-intro">Suppression de vos données...</p>
                    :
                    success ?
                        <span>
                        <p className="confirm-intro">Vos données ont été supprimées.</p>
                    </span>
                        :
                        <span>
                        <p className="confirm-intro">Une erreur est survenue.</p>
                    </span>
                }
                <div className='confirm'>
                    {loading
                        ?
                        <Spinner size='8x' spinning={'spinning'}/>
                        : null
                    }
                </div>
            </Grid>
        </Container>
    )
}
