//import config from 'config';
//import { authHeader } from '../_helpers';
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

//import Cookies from 'js-cookie';

// Private Component
import config from "../config"

const apiX = axios.create({
    baseURL: config.BASE_URL
})

export const userService = {
    login,
    logout,
    getAll,
    getUserConnexionStatus
};

function login(userData) {
    return apiX
        .post("api/users/login", userData, )
        .then(res => {
            const {token} = res.data;
            // Cookies.set("tokenPro", token)
            // Cookies.set("keepSignIn", 1)


            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token)

            // Set current user info
            localStorage.setItem("token", token)
            localStorage.setItem("keepMeIn", 1)
            localStorage.setItem('user', decoded.email)
            localStorage.setItem('cid', decoded.cid)
            localStorage.setItem('cname', decoded.cname)
            return res;

            //dispatch(setCurrentUser(decoded))
        })
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ username, password })
    // };
    //
    // return fetch(`/users/authenticate`, requestOptions)
    //     .then(handleResponse)
    //     .then(user => {
    //         // login successful if there's a jwt token in the response
    //         if (user.token) {
    //             // store user details and jwt token in local storage to keep user logged in between page refreshes
    //             localStorage.setItem('user', JSON.stringify(user));
    //         }
    //
    //         return user;
    //     });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("token")
    localStorage.removeItem("keepMeIn")
    localStorage.removeItem('user')
    localStorage.removeItem('cid')
    localStorage.removeItem('cname')
}

function getAll() {
    return false
    // const requestOptions = {
    //     method: 'GET',
    //     headers: authHeader()
    // };
    //
    // return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function getUserConnexionStatus() {
    return !!localStorage.getItem('user')
}
